/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/dailyStatistics";
class DailyStatisticsService {
    
    monthlypartnerdata(month, year, partnerId) {
        const response = http.get(`${controller}/monthlypartnerdata?month=${month}&year=${year}&partnerId=${partnerId}`);
        return response;
    }


}

export default new DailyStatisticsService();