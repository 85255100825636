/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { Loading } from 'react-loading-dot';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import PartnerService from "../../services/partner.service";
import AlertService from "../../services/alerts.service";
import ScheduleService from '../../services/schedule.service';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconTooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotificationIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ScheduledIcon from '@material-ui/icons/DateRange';
import UnscheduledIcon from '@material-ui/icons/EventBusy';
import ServicedIcon from '@material-ui/icons/EventAvailable';
import GroupsIcon from '@material-ui/icons/People';
import InstallIcon from '@material-ui/icons/SystemUpdate';
import DeviceDownIcon from '@material-ui/icons/MobileOff';
import SignalIcon from '@material-ui/icons/SpeakerPhone';
import RefreshIcon from '@material-ui/icons/Refresh';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardActionArea } from '@material-ui/core';
import moment from "moment";

import UpgradeSubscriptionModal from './UpgradeSubscriptionModal';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import { withStyles } from '@material-ui/core/styles';
import billingService from "../../services/billing.service";
import partnerDashboardInfoService from "../../services/partnerDashboardInfo.service";

import { COLORS } from "../../componentObjects";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    },
    dbIconSm: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '15px'
        },
    }
});

var contents = <div className="loading" style={{ display: "block" }}>
    <div className="loading-wrapper">
        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
    </div></div>;

class Dashboard extends Component {
    static displayName = Dashboard.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.onAlertsClick = this.onAlertsClick.bind(this);
        this.onJobsClick = this.onJobsClick.bind(this);
        this.onDevHealthClick = this.onDevHealthClick.bind(this);
        this.onSubscriptionsClick = this.onSubscriptionsClick.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onRefreshAlertsClick = this.onRefreshAlertsClick.bind(this);
        this.onRefreshJobsClick = this.onRefreshJobsClick.bind(this);
        this.onRefreshCustomersClick = this.onRefreshCustomersClick.bind(this);
        this.onRefreshInstallsClick = this.onRefreshInstallsClick.bind(this);
        this.getLastUpdated = this.getLastUpdated.bind(this);

        this.openUpgradeSubscriptionModal = this.openUpgradeSubscriptionModal.bind(this);
        this.showUpgradeSubscriptionModal = this.showUpgradeSubscriptionModal.bind(this);
        this.onUpgradeSubscription = this.onUpgradeSubscription.bind(this);
        this.onSubscriptionSelect = this.onSubscriptionSelect.bind(this);
        this.onRefreshSubscriptionClick = this.onRefreshSubscriptionClick.bind(this);

        this.state = {
            anchorEl: null,
            loading: true,
            alertsLoading: false,
            jobsLoading: false,
            installsLoading: false,
            customersLoading: false,
            subscriptionsLoading: false,
            earlyTermLoading: false,
            open: false,
            showUpgradeSubscription: false,
            newSubscriptionId: '0',

            partnerInfoObj: {},
            subscriptionInfo: {},
            billingSummary: {
                oneYr: 0,
                twoYr: 0,
                threeYr: 0,
                zeroToOneYr: 0,
                oneToTwoYr: 0,
                twoToThreeYr: 0,
                subscriptionOnly: 0
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.loadInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadInfo() {
        if (!this.props.isLoading)
            this.setState({ loading: true }, async () => {
                await partnerDashboardInfoService.getdashboardinfo(this.props.currentPartnerId)
                    .then(response => {
                        if (this._isMounted) {
                            if (response != null && response !== {}) {
                                this.setState({ partnerInfoObj: response }, async () => {
                                    if (this.props.hasBilling) {
                                        await billingService.getpartnersubscriptions(this.props.currentPartnerId)
                                            .then(response2 => {
                                                this.setState({ subscriptionInfo: response2 }, () => {
                                                    this.getBillingInfo(this.state.subscriptionInfo);
                                                })
                                            })
                                    }
                                    else
                                        this.setState({ loading: false }); 
                                });
                            }
                            else {
                                this.setState({ loading: false });
                            }
                        }
                            
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
            
    }

    getBillingInfo(subsInfo) {
        let periodCount = this.state.subscriptionInfo !== null && this.state.subscriptionInfo !== undefined && this.state.subscriptionInfo.billingPeriods !== undefined ? this.state.subscriptionInfo.billingPeriods.length : 0;
        if (this.state.subscriptionInfo !== undefined && this.state.subscriptionInfo.subscriptionsDict !== null && this.state.subscriptionInfo.subscriptionsDict !== undefined && this.state.subscriptionInfo.subscriptionsDict !== {}) {
            let subscriptionOnlyCount = this.state.subscriptionInfo.subscriptionsCount;
            let list = this.state.subscriptionInfo.subscriptionsDict.filter(s => s.end === null || s.end === "");//["All"].activeSubscriptionsByPeriodDict[0];
            let earlyTermList = this.state.subscriptionInfo.subscriptionsDict.filter(s => s.end !== null && s.end !== "");//["All"].earlyTerminationSubscriptionsByPeriodDict[0];

            let oneYr = list.filter(s => s.duration.includes("1")).length;
            let twoYr = list.filter(s => s.duration.includes("2")).length;
            let threeYr = list.filter(s => s.duration.includes("3")).length;

            let earlyTerms = earlyTermList.length > 0 ? earlyTermList.filter(s => s.end !== null && s.end !== "") : [];
            let twoToThree = earlyTerms.length > 0 ? earlyTerms.filter(s => !s.remaining.includes("Years")).length : 0;
            let oneToTwo = earlyTerms.length > 0 ? earlyTerms.filter(s => s.remaining.includes("1 Year")).length : 0;
            let zeroToOne = earlyTerms.length > 0 ? earlyTerms.filter(s => s.remaining.includes("2 Year")).length : 0;

            let summary = {
                oneYr: oneYr,
                twoYr: twoYr,
                threeYr: threeYr,
                zeroToOneYr: zeroToOne,
                oneToTwoYr: oneToTwo,
                twoToThreeYr: twoToThree,
                subscriptionOnly: subscriptionOnlyCount
            };

            this.setState({ billingSummary: summary, loading: false });
        }
        else {
            this.setState({
                billingSummary: {
                    oneYr: 0,
                    twoYr: 0,
                    threeYr: 0,
                    zeroToOneYr: 0,
                    oneToTwoYr: 0,
                    twoToThreeYr: 0,
                    subscriptionOnly: 0
                },
                loading: false
            });
        }
    }

    handleMenu(event) {
        this.setState({
            anchorEl: event.currentTarget,
            open: !this.state.open
        });
    };

    handleClose() {
        this.setState({
            anchorEl: null,
            open: false
        });
    };

    onRefreshAlertsClick() {
        Event("Refresh Alerts Clicked", "User refreshed alerts on dashboard", "Refresh Alerts Clicked");
        this.setState({ alertsLoading: true }, async () => {
            await PartnerService.refreshdashboardsection("ALERTS", this.state.partnerInfoObj)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ partnerInfoObj: response }, () => {
                            this.setState({ alertsLoading: false });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onRefreshJobsClick() {
        Event("Refresh Jobs Clicked", "User refreshed jobs on dashboard", "Refresh Jobs Clicked");
        this.setState({ jobsLoading: true }, async () => {
            await PartnerService.refreshdashboardsection("JOBS", this.state.partnerInfoObj)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ partnerInfoObj: response }, () => {
                            this.setState({ jobsLoading: false });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onRefreshCustomersClick() {
        Event("Refresh Customers Clicked", "User refreshed customers on dashboard", "Refresh Customers Clicked");
        this.setState({ customersLoading: true }, async () => {
            await PartnerService.refreshdashboardsection("CUSTOMERS", this.state.partnerInfoObj)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ partnerInfoObj: response }, () => {
                            this.setState({ customersLoading: false });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onRefreshInstallsClick() {
        Event("Refresh Installs Clicked", "User refreshed installs on dashboard", "Refresh Installs Clicked");
        this.setState({ installsLoading: true }, async () => {
            await PartnerService.refreshdashboardsection("INSTALLS", this.state.partnerInfoObj)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ partnerInfoObj: response }, () => {
                            this.setState({ installsLoading: false });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onRefreshSubscriptionClick() {
        Event("Refresh Subscriptions Clicked", "User refreshed subscriptions on dashboard", "Refresh Subscriptions Clicked");
        this.setState({ subscriptionsLoading: true },
            async () => {
                await billingService.getpartnersubscriptions(this.props.currentPartnerId)
                    .then(response2 => {
                        this.setState({ subscriptionInfo: response2, subscriptionsLoading: false }, () => {
                            this.getBillingInfo(this.state.subscriptionInfo);
                        })
                    });
            });
    }

    onAlertsClick = () => {
        Event("Alerts tile clicked", "User clicked alert tile to go to triage alerts page", "Alerts tile clicked");
        this.props.setActivePath("/triageAlerts/equipment");
        this.props.history.push("/triageAlerts/equipment");
    }

    onJobsClick = () => {
        Event("Jobs tile clicked", "User clicked jobs tile to go to schedule repairs page", "Jobs tile clicked");
        this.props.setActivePath("/scheduleRepairs");
        this.props.history.push("/scheduleRepairs");
    }

    onDevHealthClick = () => {
        Event("Device alerts tile clicked", "User clicked device alert tile to go to triage alerts page", "Device alerts tile clicked");
        this.props.setActivePath("/triageAlerts/device");
        this.props.history.push("/triageAlerts/device");
        /* todo make it on the 'devices' setting */
    }

    onSubscriptionsClick = () => {
        Event("Subscriptions tile clicked", "User clicked subscriptions tile to go to subscriptions page", "Subscriptions tile clicked");
        this.props.setActivePath("/subscriptions");
        this.props.history.push("/subscriptions");
    }

    openUpgradeSubscriptionModal() {
        this.showUpgradeSubscriptionModal();
    }

    showUpgradeSubscriptionModal() {
        this.setState({ showUpgradeSubscription: !this.state.showUpgradeSubscription }, () => {
            if (this.state.showUpgradeSubscription)
                ModalView('upgradeSubscription');
        });
    }

    onSubscriptionSelect(e) {
        this.setState({ newSubscriptionId: e.target.value });
    }

    onUpgradeSubscription() {
        Event("Upgrade service tier on dashboard", "User upgraded service tier from dashboard", "Upgrade service tier from dashboard");
        this.setState({ loading: true }, async () => {
            if (this.props.serviceTier !== this.state.newSubscriptionId) {
                await PartnerService.updateservicetier(this.state.partnerInfoObj.partnerOrgId, this.state.newSubscriptionId)
                    .then(response => {
                        if (this._isMounted) {
                            this.setState({ showUpgradeSubscription: false, loading: false }, () => {
                                this.props.setServiceTier(this.state.newSubscriptionId);
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
            else {
                this.setState({ showUpgradeSubscription: false, loading: false });
            }
        });
    }

    getLastUpdated() {
        var updateStr = "";

        if (this.state.partnerInfoObj !== null && this.state.partnerInfoObj !== undefined && this.state.partnerInfoObj.updatedTimeStamp !== null && this.state.partnerInfoObj.updatedTimeStamp !== undefined && this.state.partnerInfoObj.updatedTimeStamp !== "") {
            var dateStr = this.getDateStr(this.state.partnerInfoObj); 
            if (dateStr === "")
                dateStr = "unable to fetch"
            updateStr = "Last updated: " + dateStr;
        }

        return updateStr;
    }

    getDateStr(partnerObj) {
        var dateTime = partnerObj.updatedTimeStamp;
        var d = new Date(dateTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var dateStr = month + "/" + d.getDate() + "/" + d.getFullYear();
        var date = dateStr + " " + d.toLocaleTimeString('en-US');

        if (dateTime == "" || dateTime == null)
            date = "";
        else {
            if (partnerObj.alertsTimeStamp !== "" && partnerObj.alertsTimeStamp !== null) {
                var a = new Date(partnerObj.alertsTimeStamp);
                var aMonth = a.getMonth() + 1;
                var aDateStr = aMonth + "/" + a.getDate() + "/" + a.getFullYear();

                var aDate = ", Alerts: ";
                if (dateStr !== aDateStr)
                    aDate += aDateStr + " ";
                aDate += a.toLocaleTimeString('en-US');
                date += aDate;
            }

            if (partnerObj.jobsTimeStamp !== "" && partnerObj.jobsTimeStamp !== null) {
                var j = new Date(partnerObj.jobsTimeStamp);
                var jMonth = j.getMonth() + 1;
                var jDateStr = jMonth + "/" + j.getDate() + "/" + j.getFullYear();

                var jDate = ", Jobs: ";
                if (dateStr !== jDateStr)
                    jDate += jDateStr + " ";
                jDate += j.toLocaleTimeString('en-US');
                date += jDate;
            }

            if (partnerObj.customersTimeStamp !== "" && partnerObj.customersTimeStamp !== null) {
                var c = new Date(partnerObj.customersTimeStamp);
                var cMonth = c.getMonth() + 1;
                var cDateStr = cMonth + "/" + c.getDate() + "/" + c.getFullYear();

                var cDate = ", Customers: ";
                if (dateStr !== cDateStr)
                    cDate += cDateStr + " ";
                cDate += c.toLocaleTimeString('en-US'); 
                date += cDate;
            }

            if (partnerObj.installsTimeStamp !== "" && partnerObj.installsTimeStamp !== null) {
                var i = new Date(partnerObj.installsTimeStamp);
                var iMonth = i.getMonth() + 1;
                var iDateStr = iMonth + "/" + i.getDate() + "/" + i.getFullYear();

                var iDate = ", Installs: ";
                if (dateStr !== iDateStr)
                    iDate += iDateStr + " ";
                iDate += i.toLocaleTimeString('en-US'); 
                date += iDate;
            }

            if (partnerObj.subscriptionsTimeStamp !== "" && partnerObj.subscriptionsTimeStamp !== null) {
                var s = new Date(partnerObj.subscriptionsTimeStamp);
                var sMonth = s.getMonth() + 1;
                var sDateStr = sMonth + "/" + s.getDate() + "/" + s.getFullYear();

                var sDate = ", Subscriptions: ";
                if (dateStr !== sDateStr)
                    sDate += sDateStr + " ";
                sDate += s.toLocaleTimeString('en-US'); 
                date += sDate;
            }
        }

        return (date);
    }

    renderDBContent() {

        if (this.state.partnerInfoObj !== undefined) {

            let alertsContent = this.state.alertsLoading ?
                <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                    <div className="dot-flashing"></div>
                </div>
                : <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
                    <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className="col"></div>
                            <div className='col'>
                                <NotificationIcon className="dashboard-icon db-red db-red-bg" style={{ marginLeft: '5px', marginRight: '5px' }} />
                            </div>
                            <div className="col" style={{ textAlign: 'left' }}>
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.criticalCount}
                                </div>
                                <small style={{ color: "dimGray", fontSize: '85%' }}>Critical</small>
                            </div>
                            <div className="col"></div>

                        </div>
                    </div>
                    <div className="col" style={{ minHeight: '40px' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className="col"></div>
                            <div className='col'>
                                <NotificationIcon className="dashboard-icon db-orange db-orange-bg" style={{ marginLeft: '5px', marginRight: '5px' }} />
                            </div>
                            <div className="col" style={{ textAlign: 'left' }}>
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.warningCount}
                                </div>
                                <small style={{ color: "dimGray", fontSize: '85%' }}>Warning</small>
                            </div>
                            <div className="col"></div>

                        </div>
                    </div>
                </div>;

            let devHealthContent = this.state.alertsLoading ?
                <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                    <div className="dot-flashing"></div>
                </div>
                : <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
                    <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className="col"></div>
                            <div className='col'>
                                <DeviceDownIcon className="dashboard-icon db-teal" style={{ marginLeft: '5px', marginRight: '5px' }} />
                            </div>
                            <div className="col" style={{ textAlign: 'left' }}>
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.downCount}
                                </div>
                                <small style={{ color: "dimGray", fontSize: '80%' }}>Down</small>
                            </div>
                            <div className="col"></div>

                        </div>
                    </div>
                    <div className="col" style={{ minHeight: '40px' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className="col"></div>
                            <div className='col'>
                                <SignalIcon className="dashboard-icon db-purple" style={{ marginLeft: '5px', marginRight: '5px' }} />
                            </div>
                            <div className="col-4" style={{ textAlign: 'left', lineHeight: '1' }}>
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.lowSignalCount}
                                </div>
                                <small style={{ color: "dimGray", fontSize: '80%' }}>Low Signal Strength</small>
                            </div>
                            <div className="col"></div>

                        </div>
                    </div>
                </div>;

            let jobsContent = this.state.jobsLoading ?
                <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                    <div className="dot-flashing"></div>
                </div>
                : <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>

                    <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className='col'></div>
                            <div className='col'>
                                <UnscheduledIcon className="dashboard-icon-sm db-blue db-blue-bg" />
                            </div>
                            <div className="col" style={{ textAlign: 'left' }}>
                                <div className="dashboard-number db-num2">
                                    {this.state.partnerInfoObj.unscheduledCount}
                                </div>
                            </div>
                            <div className='col'></div>

                        </div>
                        <div>
                            <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Unscheduled</small>
                        </div>

                    </div>

                    <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className='col'></div>
                            <div className='col'>
                                <ScheduledIcon className="dashboard-icon-sm db-blue db-blue-bg" />
                            </div>
                            <div className="col" style={{ textAlign: 'left' }}>
                                <div className="dashboard-number db-num2">
                                    {this.state.partnerInfoObj.scheduledCount}
                                </div>
                            </div>
                            <div className='col'></div>

                        </div>
                        <div>
                            <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Scheduled</small>
                        </div>
                    </div>

                    <div className="col" style={{ minHeight: '40px' }}>
                        <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                            <div className='col'></div>
                            <div className="col">
                                <ServicedIcon className="dashboard-icon-sm db-blue db-blue-bg" />
                            </div>
                            <div className="col" style={{ textAlign: 'left' }}>
                                <div className="dashboard-number db-num2">
                                    {this.state.partnerInfoObj.servicedCount}
                                </div>
                            </div>
                            <div className='col'></div>

                        </div>
                        <div>
                            <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Serviced</small>
                        </div>
                    </div>
                </div>;

            let custContent = this.state.customersLoading ?
                <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                    <div className="dot-flashing"></div>
                </div>
                : (this.props.hasBilling ?
                    <div>
                        <h6 style={{ paddingLeft: '15px', marginBottom: '0px' }}>Customers</h6>
                        <div className="row no-gutters card-row" style={{ textAlign: 'center', margin: '10px 0px' }}>
                            <div className="col-6" style={{ borderRight: '1px solid lightgrey' }}>
                                <div className="row no-gutters dashboard-val" style={{ paddingTop: '5px' }}>
                                    <div className="col-5" style={{ justifyContent: 'right', display: 'grid' }}>
                                        <GroupsIcon className="dashboard-icon db-blue db-blue-bg" />
                                    </div>
                                    <div className="col-7" style={{ justifyContent: 'left', display: 'grid', textAlign: 'left' }}>
                                        <div className="dashboard-number" style={{ marginBottom: '0', marginTop: '5px' }}> {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.total : 0} </div>
                                        <small style={{ color: 'dimgray' }}>Total Customers</small>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ color: 'dimgray', paddingLeft: '15px' }}>
                                <small>
                                    <div className="row no-gutters" style={{ marginBottom: '3px' }}>
                                        <div className="col-7" style={{ textAlign: 'left' }}>
                                            Today
                                    </div>
                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                            {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.today : 0}
                                        </div>
                                    </div>

                                    <div className="row no-gutters" style={{ marginBottom: '3px' }}>
                                        <div className="col-7" style={{ textAlign: 'left' }}>
                                            This Week
                                    </div>
                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                            {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.thisWeek : 0}
                                        </div>
                                    </div>

                                    <div className="row no-gutters" style={{ marginBottom: '3px' }}>
                                        <div className="col-7" style={{ textAlign: 'left' }}>
                                            This Month
                                    </div>
                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                            {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.thisMonth : 0}
                                        </div>
                                    </div>

                                </small>
                            </div>
                        </div>
                    </div>
                    :<div style={{ padding: '10px' }}>
                        <div className="row no-gutters dashboard-val" style={{ paddingBottom: '4px' }}>
                            <div className="col-5" style={{ justifyContent: 'right', display: 'grid' }}>
                                <GroupsIcon className="dashboard-icon db-blue db-blue-bg" />
                            </div>
                            <div className="col-7" style={{ justifyContent: 'left', display: 'grid' }}>
                                <div className="dashboard-number" style={{ marginBottom: '0', marginTop: '5px' }}> {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.total : 0} </div>
                                <small style={{ color: 'dimgray' }}>Total Customers</small>
                            </div>
                        </div>

                        <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '20px', marginBottom: '15px' }}>
                            
                            <div className="col-4 dashboard-val">
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.today : 0}
                                </div>
                                <span style={{ color: "dimGray" }}>Today</span>
                            </div>

                            <div className="col-4" style={{ borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray' }}>
                                <div className="dashboard-val">
                                    <div className="dashboard-number">
                                        {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.thisWeek : 0}
                                    </div>
                                    <span style={{ color: "dimGray" }}>This Week</span>
                                </div>
                            </div>

                            <div className="col-4 dashboard-val">
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.customers !== null && this.state.partnerInfoObj.customers !== undefined ? this.state.partnerInfoObj.customers.thisMonth : 0}
                                </div>
                                <span style={{ color: "dimGray" }}>This Month</span>
                            </div>

                        </div>
                    </div>);

            let installContent = this.state.installsLoading ?
                <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                    <div className="dot-flashing"></div>
                </div>
                : (this.props.hasBilling ?
                    <div>
                        <h6 style={{ paddingLeft: '15px', marginBottom: '0px' }}>Installs</h6>
                        <div className="row no-gutters card-row" style={{ textAlign: 'center', margin: '10px 0px' }}>
                            <div className="col-6" style={{ borderRight: '1px solid lightgrey' }}>
                                <div className="row no-gutters dashboard-val" style={{ paddingTop: '5px' }}>
                                    <div className="col-5" style={{ justifyContent: 'right', display: 'grid' }}>
                                        <InstallIcon className="dashboard-icon db-blue db-blue-bg" />
                                    </div>
                                    <div className="col-7" style={{ justifyContent: 'left', display: 'grid', textAlign: 'left' }}>
                                        <div className="dashboard-number" style={{ marginBottom: '0', marginTop: '5px' }}> {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.total : 0} </div>
                                        <small style={{ color: 'dimgray' }}>Total Installs</small>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ color: 'dimgray', paddingLeft: '15px' }}>
                                <small>
                                    <div className="row no-gutters" style={{ marginBottom: '3px' }}>
                                        <div className="col-7" style={{ textAlign: 'left' }}>
                                            Today
                                    </div>
                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                            {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.today : 0}
                                        </div>
                                    </div>

                                    <div className="row no-gutters" style={{ marginBottom: '3px' }}>
                                        <div className="col-7" style={{ textAlign: 'left' }}>
                                            This Week
                                    </div>
                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                            {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.thisWeek : 0}
                                        </div>
                                    </div>

                                    <div className="row no-gutters" style={{ marginBottom: '3px' }}>
                                        <div className="col-7" style={{ textAlign: 'left' }}>
                                            This Month
                                    </div>
                                        <div className="col-4" style={{ textAlign: 'right' }}>
                                            {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.thisMonth : 0}
                                        </div>
                                    </div>

                                </small>
                            </div>
                        </div>
                    </div>
                    :<div style={{ padding: '10px' }}>
                        <div className="row no-gutters dashboard-val" style={{ paddingBottom: '4px' }}>
                            <div className="col-5" style={{ justifyContent: 'right', display: 'grid' }}>
                                <InstallIcon className="dashboard-icon db-blue db-blue-bg" />
                            </div>
                            <div className="col-7" style={{ justifyContent: 'left', display: 'grid' }}>
                                <div className="dashboard-number" style={{ marginBottom: '0', marginTop: '5px' }}> {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.total : 0} </div>
                                <small style={{ color: 'dimgray' }}>Total Installs</small>
                            </div>
                        </div>

                        <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '20px', marginBottom: '15px' }}>

                            <div className="col-4 dashboard-val">
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.today : 0}
                                </div>
                                <span style={{ color: "dimGray" }}>Today</span>
                            </div>

                            <div className="col-4" style={{ borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray' }}>
                                <div className="dashboard-val">
                                    <div className="dashboard-number">
                                        {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.thisWeek : 0}
                                    </div>
                                    <span style={{ color: "dimGray" }}>This Week</span>
                                </div>
                            </div>

                            <div className="col-4 dashboard-val">
                                <div className="dashboard-number">
                                    {this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.thisMonth : 0}
                                </div>
                                <span style={{ color: "dimGray" }}>This Month</span>
                            </div>

                        </div>
                    </div>);

            /* CODE FOR "Installs Need Troubleshooting" Dialogue
                <div style={{ textAlign: 'center', margin: '0px 20px', color: "dimGray", fontSize: '85%' }}>
                    <p className="dashboard-val" style={{ margin: '3px 0px' }}>
                        <small><b>{this.state.partnerInfoObj.installs !== null && this.state.partnerInfoObj.installs !== undefined ? this.state.partnerInfoObj.installs.reprovision : 0}</b> Installs Need Troubleshooting</small>
                    </p>
                </div>
            */
            let subscriptionsCount =
                (this.props.hasBilling && this.state.billingSummary.subscriptionOnly > 0) ?
                    <div className="col dashboard-val" >
                        <div className="dashboard-number" style={{ marginBottom: '4px', borderRight: '1px solid lightGray' }}>
                            {this.state.billingSummary.subscriptionOnly}
                        </div >
                        <span style={{ color: "dimGray" }}> Monitoring  < br /> only</span >
                    </div >
                    : null;
            let subscriptionContent = this.props.hasBilling ?
                (this.state.subscriptionsLoading ?
                    <div className="row no-gutters">
                        <div className="col">
                            <Card elevation={4} style={{ height: '125px' }}>
                                <CardContent style={{ padding: '10px 0px' }}>
                                    <h6 style={{ paddingLeft: '15px' }}>Subscriptions</h6>
                                    <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                                        <div className="dot-flashing"></div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    : <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onSubscriptionsClick}>
                        <div className="col">
                            <Card elevation={4} style={{ height: '125px' }}>
                                <CardContent style={{ padding: '10px 0px' }}>
                                    <h6 style={{ paddingLeft: '15px' }}>Subscriptions</h6>
                                    <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '20px', marginBottom: '15px' }}>
                                        {subscriptionsCount}  
                                        <div className="col" style={{ /*borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray'*/ }}>
                                        <div className="dashboard-val">
                                            <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                {this.state.billingSummary.oneYr}
                                            </div>
                                            <span style={{ color: "dimGray" }}>1 Year</span>
                                        </div>
                                        </div>
                                        <div className="col" style={{ borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray' }}>
                                            <div className="dashboard-val">
                                                <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                    {this.state.billingSummary.twoYr}
                                                </div>
                                                <span style={{ color: "dimGray" }}>2 Year</span>
                                            </div>
                                        </div>

                                        <div className="col dashboard-val">
                                            <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                {this.state.billingSummary.threeYr}
                                            </div>
                                            <span style={{ color: "dimGray" }}>3 Year</span>
                                        </div>

                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>)
                : null;

            let earlyTermContent = this.props.hasBilling ?
                (this.state.earlyTermsLoading ?
                    <div className="row no-gutters">
                        <div className="col">
                            <Card elevation={4} style={{ height: '125px' }}>
                                <CardContent style={{ padding: '10px 0px' }}>
                                    <h6 style={{ paddingLeft: '15px' }}>Early Terminations</h6>
                                    <div className='row no-gutters card-row centered' style={{ minHeight: '52px' }}>
                                        <div className="dot-flashing"></div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    : <div className="row no-gutters">
                        <div className="col">
                            <Card elevation={4} style={{ height: '125px' }}>
                                <CardContent style={{ padding: '10px 0px' }}>
                                    <h6 style={{ paddingLeft: '15px' }}>Early Terminations</h6>
                                    <div className="row no-gutters card-row" style={{ textAlign: 'center', fontSize: '80%', marginTop: '20px', marginBottom: '15px' }}>

                                        <div className="col-4 dashboard-val">
                                            <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                {this.state.billingSummary.zeroToOneYr}
                                            </div>
                                            <span style={{ color: "dimGray" }}>0-1 Year</span>
                                        </div>

                                        <div className="col-4" style={{ borderLeft: '1px solid lightGray', borderRight: '1px solid lightGray' }}>
                                            <div className="dashboard-val">
                                                <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                    {this.state.billingSummary.oneToTwoYr}
                                                </div>
                                                <span style={{ color: "dimGray" }}>1-2 Year</span>
                                            </div>
                                        </div>

                                        <div className="col-4 dashboard-val">
                                            <div className="dashboard-number" style={{ marginBottom: '4px' }}>
                                                {this.state.billingSummary.twoToThreeYr}
                                            </div>
                                            <span style={{ color: "dimGray" }}>2-3 Year</span>
                                        </div>

                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>)
                : null;

            return (
                <div>
                    <div className="row" style={{ padding: '30px' }}>

                        <div className="col-lg-4 col-md-6" style={{ display: 'grid', marginBottom: '20px' }}>
                            <div>

                                <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onAlertsClick}>
                                    <div className="col">

                                        <Card elevation={4} style={{ height: this.props.hasBilling ? '125px' : 'unset' }}>
                                            <CardActionArea style={{ height: '100%' }}>
                                                <CardContent style={{ padding: '10px 0px' }}>

                                                    <h6 style={{ paddingLeft: '15px' }}>Alerts Report</h6>
                                                    {alertsContent}

                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onDevHealthClick}>
                                    <div className="col">

                                        <Card elevation={4} style={{ height: this.props.hasBilling ? '125px' : 'unset' }}>
                                            <CardActionArea style={{ height: '100%' }}>
                                                <CardContent style={{ padding: '10px 0px' }}>

                                                    <h6 style={{ paddingLeft: '15px' }}>Device Health Report</h6>
                                                    {devHealthContent}

                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row no-gutters" onClick={this.onJobsClick}>
                                    <div className="col">

                                        <Card elevation={4} style={{ height: this.props.hasBilling ? '125px' : 'unset' }}>
                                            <CardActionArea style={{ height: '100%' }}>
                                                <CardContent style={{ padding: '10px 0px' }}>

                                                    <h6 style={{ paddingLeft: '15px' }}>Jobs Report</h6>
                                                    {jobsContent}

                                                </CardContent>
                                            </CardActionArea></Card>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" style={{ display: 'grid' }}>
                            <div>
                                <div className="row no-gutters" style={{ marginBottom: this.props.hasBilling ? '20px' : '25px' }}>
                                    <div className="col">
                                        <Card elevation={4} style={{ height: this.props.hasBilling ? '125px' : 'unset' }}>
                                            <CardContent style={{ padding: this.props.hasBilling ? '10px 0px' : '10px' }}>

                                                {custContent}

                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row no-gutters" style={{ marginBottom: this.props.hasBilling ? '20px' : 'unset' }}>
                                    <div className="col">
                                        <Card elevation={4} style={{ height: this.props.hasBilling ? '125px' : 'unset' }}>
                                            <CardContent style={{ padding: '10px 0px' }}>

                                                {installContent}

                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>

                                {subscriptionContent}
                                {earlyTermContent}

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" style={{ display: 'grid' }}>
                            <div>

                                <div className="row" style={{ height: '100%' }}>
                                    <div className="col-12" style={{ display: 'grid' }}>
                                        <Card elevation={4}>
                                            <CardContent style={{ padding: '10px 0px', }}>
                                                <h6 style={{ paddingLeft: '15px' }}>Alerts by Alert Type</h6>

                                                <div className="row no-gutters">
                                                    <div className="col">

                                                        <ResponsiveContainer height={325}>
                                                            <PieChart width={200} height={300} onMouseEnter={this.onPieEnter}>
                                                                <Pie 
                                                                    data={this.state.partnerInfoObj.commonAlertTypesList}
                                                                    innerRadius={60}
                                                                    outerRadius={80}
                                                                    fill="#8884d8"
                                                                    paddingAngle={5}
                                                                    dataKey="typeCount"
                                                                    opacity="65%"
                                                                    label
                                                                >
                                                                    {this.state.partnerInfoObj.commonAlertTypesList !== undefined && this.state.partnerInfoObj.commonAlertTypesList !== null &&
                                                                        (this.state.partnerInfoObj.commonAlertTypesList.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                        )))
                                                                        
                                                                    }
                                                                </Pie>

                                                                <Legend verticalAlign="bottom" iconType="circle" iconSize={6} wrapperStyle={{ opacity: '75%', padding: '0px 10px' }}/>
                                                            </PieChart>
                                                        </ResponsiveContainer>

                                                    </div>
                                                </div>

                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else
            return (<div></div>);
    }

    render() {
        const { classes } = this.props;

        if (this.state.loading) {
            contents = <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else {
            contents = this.renderDBContent(); 
        }

        let subscriptionButton = this.props.hasServiceTier ?
            <div className="row no-gutters">
                <div className="col">
                    <div className="btn-right" style={{ marginRight: '0' }}>
                        <button type="button" className="secondary-btn btn-large" onClick={this.openUpgradeSubscriptionModal} style={{ marginRight: '30px', width: 'unset' }}>Upgrade Subscription Level?</button>
                    </div>
                </div>
            </div>
            : null;
        let subscriptionOption = this.props.hasBilling ?
            <MenuItem onClick={this.onRefreshSubscriptionClick}>Refresh Subscription Count</MenuItem>
            : null;

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Hello, {this.props.currentAccount.firstName}
                    </h2>
                    <div className="row no-gutters">
                        <div className="col">
                            <div style={{ color: 'dimgrey', fontStyle: 'italic', padding: '4px 0 0 30px' }}>
                                Welcome to your Dashboard
                            </div>
                        </div>
                        <div className="col">
                            <div className="btn-right" style={{ color: 'dimgrey', fontStyle: 'italic', fontSize: '75%' }}>
                                {this.getLastUpdated()}
                                <IconTooltip title="Refresh" enterDelay={200} leaveDelay={200} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                    <IconButton
                                        aria-label="refresh partner dashboard"
                                        aria-controls="menu-refresh"
                                        aria-haspopup="true"
                                        onClick={this.handleMenu}
                                        style={{ padding: '0px', marginLeft: '5px' }}
                                    >
                                        <RefreshIcon style={{ color: this.state.loading ? "white" : "dimgrey", height: '15px', width: '15px' }} />
                                    </IconButton>
                                    
                                </IconTooltip>
                                <Menu
                                    id="menu-refresh"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={this.state.open}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.onRefreshAlertsClick}>Refresh Alerts</MenuItem>
                                    <MenuItem onClick={this.onRefreshJobsClick}>Refresh Jobs</MenuItem>
                                    <MenuItem onClick={this.onRefreshCustomersClick}>Refresh Customers</MenuItem>
                                    <MenuItem onClick={this.onRefreshInstallsClick}>Refresh Installs</MenuItem>
                                    {subscriptionOption}
                                </Menu>
                            </div>
                        </div>
                    </div>
                    {contents}

                    {subscriptionButton}

                </div>

                <UpgradeSubscriptionModal show={this.state.showUpgradeSubscription} onClick={this.showUpgradeSubscriptionModal} onSubscriptionSelect={this.onSubscriptionSelect} onSave={this.onUpgradeSubscription} serviceTier={this.state.newSubscriptionId !== '0' ? this.state.newSubscriptionId : this.props.serviceTier} />
            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);
