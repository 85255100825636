/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { SetPageViewTitle, Event } from "../GoogleAnalytics";
import { NavigationBar } from '../NavigationBar';
import Button from 'react-bootstrap/Button';
import IconButton from '@material-ui/core/IconButton';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import eulaFile from '../../txt/EULA.txt';
import RegisterUserService from "../../services/registeruser.service";
import { withStyles } from '@material-ui/core/styles';

import DOMPurify from 'dompurify';

const STATES = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    },
    zipSpacer: {
        [theme.breakpoints.down('md')]: {
            display: 'none !important'
        }
    }
});

const jsHomeowner = {
    firstName: "",
    lastName: "",
    company: "",
    description: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    locationName: "",
    phone: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    parentOrganizationId: null,
    organizationId: null,
    userId: null,
    houseAssetId: null
};

class RegisterHomeowner extends Component {
    static displayName = RegisterHomeowner.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.setLocation = this.setLocation.bind(this);
        this.getHomeownerAccount = this.getHomeownerAccount.bind(this);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeLocationName = this.onChangeLocationName.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeZip = this.onChangeZip.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);

        this.handleNext = this.handleNext.bind(this);
        this.handleCloseEULA = this.handleCloseEULA.bind(this);
        this.handleAcceptEULA = this.handleAcceptEULA.bind(this);

        this.validateForm = this.validateForm.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.registerHomeowner = this.registerHomeowner.bind(this);

        this.onViewPasswordClick = this.onViewPasswordClick.bind(this);

        this.state = {
            userId: -1,
            loading: false,
            isVerified: false,
            newAccount: jsHomeowner,
            eulaTxt: "",
            showEULA: false,
            eulaAccepted: false,
            validated: false,
            isRegistered: false,
            prevRegistered: false,
            errorMsg: "",
            longitude: null,
            latitude: null,
            statesList: STATES,
            invalidPassword: false,
            confirmPasswordMatch: true,
            passwordError: "",
            registrationError: "",
            viewPassword: false,
            passwords: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        SetPageViewTitle('registerHomeowner');

        //if ("geolocation" in navigator) {
        //    console.log("Geolocation Available");
        //    navigator.geolocation.getCurrentPosition((position) => {
        //        console.log("Latitude is :", position.coords.latitude);
        //        console.log("Longitude is :", position.coords.longitude);
        //        //this.setLocation(position.coords.longitude, position.coords.latitude);
        //        //location.latitude = position.coords.latitude;
        //        //location.longitude = position.coords.longitude;
        //        this.setLocation(position.coords.latitude, position.coords.longitude);
        //    }, function (error) {
        //        console.error("Error Code = " + error.code + " - " + error.message);
        //    });

        //} else {
        //    console.log("Geolocation Not Available");
        //}

        //fetch('10k-most-common.txt')
        //    .then(p => p.text())
        //    .then(text => {

        //        this.setState({ passwords: text.split("\n") });
                
        //    });

        this.getHomeownerAccount();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setLocation(lat, long) {
        console.log("setting... Lat: " + lat + ", Long: " + long);
        this.setState({ latitude: lat, longitude: long });
    }

    getHomeownerAccount() {
        this.setState({ loading: true }, async () => {
            await RegisterUserService.gethomeowneraccount(this.props.registerOrgId, this.props.registerUserId)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({ loading: false, newAccount: response.data }, () => {
                            if (this.state.newAccount !== null) {
                                let accObj = this.state.newAccount;
                                //accObj.parentAccountId = this.props.registerParentOrgId;
                                //console.log("Parent Acc Id set to: " + this.props.registerParentOrgId);
                                this.setState({ newAccount: accObj });
                            }
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ loading: false });
                });
        });
    }

    onChangeEmail(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    email: newValue
                }
            };
        });
    }

    onChangeFirstName(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    firstName: newValue
                }
            };
        });
    }

    onChangeLastName(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    lastName: newValue
                }
            };
        });
    }

    onChangePhone(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    phone: newValue,
                    phoneNumber: newValue
                }
            };
        });
    }

    onChangePassword(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    password: newValue
                }
            };
        });
    }

    onChangeConfirmPassword(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    confirmPassword: newValue
                }
            };
        });
    }    

    onChangeLocationName(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    locationName: newValue,
                    description: newValue,
                }
            };
        });
    }

    onChangeAddress(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    address: newValue
                }
            };
        });
    }

    onChangeCity(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    city: newValue
                }
            };
        });
    }

    onChangeState(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    state: newValue
                }
            };
        });
    }

    onChangeZip(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    zip: newValue
                }
            };
        });
    }

    onChangeCountry(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newAccount: {
                    ...prevState.newAccount,
                    country: newValue
                }
            };
        });
    }

    handleNext(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (this.validateForm() && form.checkValidity() === true) {            
            fetch(eulaFile)
                .then(response => response.text())
                .then(textContent => {
                    this.setState({ eulaTxt: DOMPurify.sanitize(textContent) }, () => {
                        this.setState({ showEULA: true });
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        }

        this.setState({ validated: true });
    }

    handleCloseEULA() {
        this.setState({ showEULA: false });
    }

    handleAcceptEULA() {
        Event("EULA Accepted", "User accepted EULA", "EULA Accepted");
        this.setState({ showEULA: false, eulaAccepted: true }, () => {
            this.registerHomeowner();
        });
    }

    registerHomeowner() {
        Event("Register Homeowner", "User registered homeowner account", "Register Homeowner");
        this.setState({ loading: true }, async () => {

            let regAccount = this.state.newAccount;
            regAccount.password = this.state.newAccount.password;
            regAccount.confirmPassword = regAccount.password;
            regAccount.latitude = this.props.latitude;
            regAccount.longitude = this.props.longitude;
            let phoneStr = regAccount.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('+', '');
            regAccount.phoneNumber = phoneStr;
            regAccount.organizationId = regAccount.accountId;
            regAccount.parentOrganizationId = regAccount.parentAccountId;
            regAccount.locationName = regAccount.description;

            await RegisterUserService.registerhomeowner(regAccount)
                .then(response => {
                    if (this._isMounted) {
                        if (response.data === "Success") {
                            this.setState({ isRegistered: true, loading: false });
                        }
                        else { 
                            this.setState({ registrationError: response.data, isRegistered: false, loading: false });
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ registrationError: e, isRegistered: false, loading: false });
                });
        });
    }

    validateForm() {
        var isValid = true;
        const account = this.state.newAccount;

        if (!this.validatePassword(account.password))
            isValid = false;

        if (!this.validateConfirmPassword(account.password, account.confirmPassword))
            isValid = false;

        /*if (account.password !== account.confirmPassword)
            isValid = false;*/

        return isValid;
    }

    checkPersonalData(pwd) {
        let isPersonal = false;
        let userEmail = this.state.newAccount.email.split("@")[0];

        if (pwd.includes(this.state.newAccount.email.toLowerCase()))
            isPersonal = true;

        if (pwd.includes(userEmail.toLowerCase()))
            isPersonal = true;

        if (pwd.includes(this.state.newAccount.firstName.toLowerCase()))
            isPersonal = true;
        if (pwd.includes(this.state.newAccount.lastName.toLowerCase()))
            isPersonal = true;

        return isPersonal;
    }

    checkCommonPassword(pwd) {
        let isCommon = false;
        let localpasswords = this.state.passwords;

        for (let i = 0; i < localpasswords.length; i++) {
            let str = localpasswords[i];
            if (pwd.includes(str))
                isCommon = true;
        }

        return isCommon;
    }

    validateConfirmPassword(pwd, confirmPwd) {
        console.log("comparing: " + pwd + ' to ' + confirmPwd);
        var pwdMatch = false;

        if (pwd == null)
            pwd = "";
        if (confirmPwd == null)
            confirmPwd = "";

        if (pwd === confirmPwd)
            pwdMatch = true;

        if (pwdMatch) {
            this.setState({ confirmPasswordMatch: true });
            return true;
        }
        else {
            this.setState({ confirmPasswordMatch: false });
            return false;
        }
    }

    validatePassword(pwd) {
        console.log("validating: " + pwd);
        var errors = [];

        if (pwd == null)
            pwd = "";

        var hasLower = /[a-z]/g.test(pwd);
        if (!hasLower)
            errors = errors.concat(['a lowercase letter']);

        var hasUpper = /[A-Z]/g.test(pwd);
        if (!hasUpper)
            errors = errors.concat(['an uppercase letter']);

        var regex = /\d+/g;
        var hasNumber = regex.test(pwd);
        console.log(hasNumber);
        if (!hasNumber)
            errors = errors.concat(['a number']);

        var regex2 = /[!@#$%^&*]+/g;
        var hasCharacter = regex2.test(pwd);
        if (!hasCharacter)
            errors = errors.concat(['a special character']);

        var hasLength = pwd.length >= 8;
        if (!hasLength)
            errors = errors.concat(['at least 8 characters']);

        // check for common and personal data..
        //if (this.checkCommonPassword(this.state.newAccount.password.toLowerCase())) {
        //    errors = errors.concat(["is too common"]);
        //}
        if (this.checkPersonalData(this.state.newAccount.password.toLowerCase())) {
            errors = errors.concat(["cannot contain personal information"]);
        }
                
        console.log("Finished validation, " + errors.length + " errors");
        if (errors.length) {
            console.log(errors);
            var errorMsg = "Is missing: " + errors.join(", ");
            console.log(errorMsg);
            this.setState({ invalidPassword: true, passwordError: errorMsg });
            return false;
        }
        else {
            this.setState({ invalidPassword: false, passwordError: "" });
            return true;
        }
    }

    onViewPasswordClick() {
        Event("View Password Clicked", "User clicked view password in Register Homeowner page", "View Password Clicked");
        this.setState({ viewPassword: !this.state.viewPassword });
    }

    renderPage() {
        const { classes } = this.props;

        return (

            <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>
                <Form noValidate validated={this.state.validated && !this.state.invalidPassword && this.state.confirmPasswordMatch} onSubmit={this.handleNext} >
                    <Row>
                        <Col sm="12" md="6">

                            <Form.Group controlId="email" disabled readOnly>
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" placeholder="user@email.com" value={this.state.newAccount.email} onChange={this.onChangeEmail} readOnly/>
                                <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required type="text" placeholder="First Name" value={this.state.newAccount.firstName} onChange={this.onChangeFirstName} />
                                <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="text" placeholder="Last Name" value={this.state.newAccount.lastName} onChange={this.onChangeLastName} />
                                <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="phone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control required type="tel" placeholder="(123)456-7890" value={this.state.newAccount.phone == null ? "" : this.state.newAccount.phone} onChange={this.onChangePhone} />
                                <Form.Control.Feedback type="invalid">Phone Number is required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="password" autoFocus>
                                <Form.Label style={{ fontWeight: '500' }}>Password</Form.Label>
                                <InputGroup>
                                    <Form.Control required type={this.state.viewPassword ? "text" : "password"} placeholder="" value={this.state.newAccount.password == null ? "" : this.state.newAccount.password} onChange={this.onChangePassword} autoFocus isValid={this.state.validated && !this.state.invalidPassword} isInvalid={this.state.invalidPassword} style={{ /*borderColor: '#28a745', boxShadow: '0 0 0 0.2rem rgba(40, 167, 69, 0.25)'*/ }} />
                                    <IconButton onClick={this.onViewPasswordClick} style={{ padding: '3px', borderRadius: '0', borderTop: '1px solid #ced4da', borderRight: '1px solid #ced4da', borderBottom: '1px solid #ced4da', borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem' }}>
                                        {this.state.viewPassword ?
                                            <VisibilityOffIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                            : <VisibilityIcon style={{ width: '28px', height: '28px', padding: '3px' }} />
                                        }
                                    </IconButton>                                    
                                    <Form.Control.Feedback type="invalid">{this.state.passwordError}</Form.Control.Feedback>
                                </InputGroup>
                                <Form.Text muted>8+ characters: at least 1 lowercase, uppercase, digit and special character (!@#$%^&amp;*)</Form.Text>
                            </Form.Group>

                            <Form.Group controlId="confirmPassword">
                                <Form.Label style={{ fontWeight: '500' }}>Confirm Password</Form.Label>
                                <Form.Control required type={this.state.viewPassword ? "text" : "password"} placeholder="" value={this.state.newAccount.confirmPassword == null ? "" : this.state.newAccount.confirmPassword} onChange={this.onChangeConfirmPassword} isInvalid={!this.state.confirmPasswordMatch} />
                                <Form.Control.Feedback type="invalid">Confirm Password is required and must match Password</Form.Control.Feedback>
                            </Form.Group>

                        </Col>

                        <Col sm="12" md="6">

                            <Form.Group controlId="locationName">
                                <Form.Label>Location Name</Form.Label>
                                <Form.Control required type="text" placeholder="ex: Austin House" value={this.state.newAccount.locationName == null ? (this.state.newAccount.description == null ? "" : this.state.newAccount.description) : this.state.newAccount.locationName} onChange={this.onChangeLocationName} />
                                <Form.Control.Feedback type="invalid">Location Name is required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control required type="text" placeholder="ex: 1234 Main St." value={this.state.newAccount.address} onChange={this.onChangeAddress} />
                                <Form.Control.Feedback type="invalid">Address is required</Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control required type="text" placeholder="ex: Austin" value={this.state.newAccount.city} onChange={this.onChangeCity} />
                                <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
                            </Form.Group>

                            {/*<Form.Group controlId="state">
                                <Form.Label>State</Form.Label>
                                <Form.Control required type="text" placeholder="ex: Texas" value={this.state.newAccount.state} onChange={this.onChangeState} />
                                <Form.Control.Feedback type="invalid">State is required</Form.Control.Feedback>
                            </Form.Group>*/}

                            <Form.Group controlId="select-state">
                                <Form.Label>State</Form.Label>
                                <Form.Control as="select" value={this.state.newAccount.state} onChange={(e) => this.onChangeState(e)}>
                                    {STATES.map((opt, i) =>
                                        (<option key={`state-${i}`} value={opt}>{opt}</option>)
                                    )}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="zip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control required type="text" placeholder="ex: 12345" value={this.state.newAccount.zip} onChange={this.onChangeZip} pattern="[0-9]{5}" />
                                <Form.Text className={classes.zipSpacer} style={{ color: 'white', userSelect: 'none' }}>8+ characters: at least 1 lowercase, uppercase, digit and special character (!@#$%^&amp;*)</Form.Text>
                                <Form.Control.Feedback type="invalid">Valid zip code is required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="country">
                                <Form.Label>Country</Form.Label>
                                <Form.Control type="text" placeholder="ex: United States" value={this.state.newAccount.country == null ? "" : this.state.newAccount.country} onChange={this.onChangeCountry} />
                            </Form.Group>

                        </Col>

                    </Row>

                    <Button type="submit" style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}> Next</Button>
                </Form>
            </div>

        );
    }

    renderConfirmation() {
        let alert = this.state.isRegistered ?
            <Alert variant="success" style={{ padding: '20px 25px 15px' }}>
                <Alert.Heading>Success!</Alert.Heading>
                <p>Thank you for registering your account — Log in the <strong>LifeWhere Mobile App</strong> to set up your devices and view your personal LifeWhere dashboard</p>
                <hr />
                <p>Click <Alert.Link href="https://apps.apple.com/us/app/pro-iq-lifewhere/id1291952900">here</Alert.Link> to download the iOS app or <Alert.Link href="https://play.google.com/store/apps/details?id=com.lifewhere.home">here</Alert.Link> to download the Android app</p>
            </Alert>
            : !this.state.registrationError.includes("usedEmail") ?
                <Alert variant="danger" style={{ padding: '20px 25px 15px' }}>
                    <Alert.Heading>Error</Alert.Heading>
                    <p>Something went wrong while registering your LifeWhere account - Please try again later or call customer support</p>
                    <p>{this.state.registrationError}</p>
                </Alert>
                : <Alert variant="danger" style={{ padding: '20px 25px 15px' }}>
                    <Alert.Heading>Error</Alert.Heading>
                    <p>Customer with this email <b>{this.state.newAccount.email}</b> already exists</p>
                </Alert>;

        return (
            <div style={{ margin: '25px 30px' }}>
                {alert}
            </div>
        );
    }

    renderPrevRegistered() {
        return (
            <div style={{ margin: '25px 30px' }}>
                <Alert variant="primary" style={{ padding: '20px 25px 15px' }}>
                    <Alert.Heading>Previously Registered</Alert.Heading>
                    <p>It looks like this LifeWhere account was previously registered - if this is incorrect please contact customer support</p>
                </Alert>
            </div>
        );
    }

    renderEULA() {

        return (
            <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>                
                <p style={{ height: '450px', overflow: 'scroll', paddingLeft: '15px', paddingRight: '15px' }} dangerouslySetInnerHTML={{ __html: this.state.eulaTxt }} />
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Button onClick={this.handleAcceptEULA} style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}>Accept</Button>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ?
            <div className="loading" style={{ width: '100%', marginLeft: '0px', display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : (this.state.showEULA ?
                this.renderEULA()
                :(this.state.eulaAccepted ?
                    this.renderConfirmation()
                    : (this.state.prevRegistered ?
                        this.renderPrevRegistered()
                        : this.renderPage()
                    )));

        return (
            <div>
                <div style={{ marginLeft: '30px', marginTop: '25px' }}>
                    <h1 id="tabelLabel" >Register LifeWhere Account</h1>

                </div>
                <NavigationBar onLogOff={this.onLogOff} showButton={false} />
                {contents}

                {/*<Dialog open={this.state.showEULA} onClose={this.handleCloseEULA} scroll='paper' aria-labelledby="eula-dialog">
                    <DialogTitle id="eula-title" style={{ textAlign: 'center', justifyContent: 'center' }}>End User License Agreement</DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText id="eula-text" tabIndex={-1}>
                            {this.state.eulaTxt}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <Button onClick={this.handleAcceptEULA} style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}>Accept</Button>
                    </DialogActions>
                </Dialog>*/}

            </div>
        );
    }
}

export default withStyles(styles)(RegisterHomeowner);