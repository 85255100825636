/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/account";
class AccountService {

    
    async isValid(password) {
        const response = await http.post(`${controller}/validate`, {password});
        const data = response;
        return data;
    }
    async accountinfo() {
        const response = await http.get(`${controller}/accountinfo`);
        const data = response;
        return data;
    } 

    updateuserinfo(data) {
        return http.put(`${controller}/updateuserinfo`, data);
    }

    async uploadaccountimage(file) {
        const response = await http.post(`${controller}/uploadaccountimage/`, file);
        const data = response;
        return data;
    }

    async get(id, getUsers = true) {
        const response = await http.get(`${controller}?organizationId=${id}&getUsers=${getUsers}`);
        const data = response;
        return data;
    }

    async getpartnerinfo(id, justUsers = false) {
        const response = await http.get(`${controller}/getpartnerinfo?organizationId=${id}&justUsers=${justUsers}`);
        const data = response;
        return data;
    }
    

    async uploadimage(file) {
        const response = await http.post(`${controller}/uploadimage/`, file);
        const data = response;
        return data;
    }

    invite(data) {
        return http.post(`${controller}/invite`, data);
    }

    inviteusers(name, id, users) {
        return http.post(`${controller}/inviteusers?orgName=${name}&orgId=${id}`, users);
    }

    inviteusersloop(name, id, users) {
        return http.post(`${controller}/inviteusersloop?orgName=${name}&orgId=${id}`, users);
    }

    disable(username, userId, orgId) {
        return http.post(`${controller}/disable?username=${encodeURIComponent(username)}&userId=${userId}&organizationId=${orgId}`);
    }

    updateorg(data) {
        return http.put(`${controller}/updateorg`, data);
    }

    updateuser(parentAccountId, data) {
        return http.post(`${controller}/updateuser?parentAccountId=${parentAccountId}`, data)
    }

    update(data) {
        return http.put(`${controller}/updatecustomeraccount`, data);
    }

    delete(id) {
        return http.delete(`${controller}?organizationId=${id}`);
    }

    softDeleteAccount(partnerOrgId, customerOrgId) {
        return http.delete(`${controller}/movetodeletedorg?partnerOrgId=${partnerOrgId}&customerOrgId=${customerOrgId}`)
    }

    recoverAccount(partnerOrgId, customerOrgId) {
        return http.post(`${controller}/recoverAccount?partnerOrgId=${partnerOrgId}&customerOrgId=${customerOrgId}`)
    }

    deleteaccountassets(id) {
        return http.delete(`${controller}/accountassets?organizationId=${id}`);
    }

    deleteuser(orgId, userId) {
        return http.delete(`${controller}/deleteuser?organizationId=${orgId}&userId=${userId}`);
    }

    async logon(user) {
        const response = http.post(`${controller}/logon`, user);
        const data = await response;
        return data;
    }

    partnerdeletedaccounts(partnerOrgId) {
        return http.get(`${controller}/partnerdeletedaccounts?partnerOrgId=${partnerOrgId}`);
    }

    getRolesList(orgId) {
        return http.get(`${controller}/getroleslist?orgId=${orgId}`);
    }
}

export default new AccountService();