/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { Event } from "../GoogleAnalytics";
import "../../CSS/DataModal.css";
import AlertService from '../../services/alerts.service';
import CommentsService from '../../services/comments.service';
import AskSupportModal from './AskSupportModal';
import { jsConversation, ConversationTypeEnum, TagIdsEnum } from "../../componentObjects";

const ADMIN_ORG = "LifeWhere";

class CommentsModal extends React.Component {

    constructor(props) {
        super(props);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.onAskSupportClick = this.onAskSupportClick.bind(this);
        this.onAnswerClick = this.onAnswerClick.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.askSupportConfirmed = this.askSupportConfirmed.bind(this);
        this.generateCheckboxSelected = this.generateCheckboxSelected.bind(this);
        this.getCheckbox = this.getCheckbox.bind(this);
        this.changeCheckbox = this.changeCheckbox.bind(this);
        this.addComment = this.addComment.bind(this);

        this.state = {
            commentValue: "",
            newComment: jsConversation,
            currentConversation: null,
            isInternalComment: true,
            commentsAdded: 0,
            showAskSupport: false,
            askSupportMessage: "",
            askSupportTitle: "",
            isCloseOnly: false,
            isSelectCommentModal: false,
            checkboxSelected: [],
            returnToAlerts: false
        }
    }

    onClick = e => {
        if (!this.state.isSelectCommentModal) {
            this.props.onClick && this.props.onClick(e);
        }
    };

    onCancel() {
        this.setState({ showAskSupport: !this.state.showAskSupport });
        if (this.state.returnToAlerts) {
            this.onModalButtonClick();
            this.props.onClick();
        }
    }

    onModalButtonClick() {
        if (!this.state.isSelectCommentModal) {
            this.props.resetConvo(this.state.commentsAdded);
            if (this.props.userOrg === ADMIN_ORG) {
                this.setState({ currentConversation: null, isInternalComment: true, commentsAdded: 0 });
            }
            else {
                this.setState({ currentConversation: null, isInternalComment: false, commentsAdded: 0 });
            }
        }
        else {
            this.setState({ isSelectCommentModal: false });
        }
        
    };

    onAskSupportClick() {
        //var alert = this.props.commentAlert != null ? this.props.commentAlert : this.props.alert;
        var alertComment = this.props.alertComment;
        var convo = this.state.currentConversation ? this.state.currentConversation : this.props.conversation;
        var title = "";
        var message = "";
        var isCloseOnly = true;
        var isSelectCommentModal = false;
        var showAskSupport = !this.state.showAskSupport;
        if (convo == null || convo.length === 0) {
            message = "Please leave your question in a comment before asking for support";
        }
        else if (alertComment !== null && alertComment !== undefined && alertComment.hasQuestion) {
            message = "Question already submitted and waiting for Support response. Please allow up to 24 hours for a response on your question"
        }
        else {
            isCloseOnly = false;
            isSelectCommentModal = true;
            showAskSupport = false;
            this.generateCheckboxSelected();
        }
        this.setState({ askSupportTitle: title, askSupportMessage: message, isCloseOnly: isCloseOnly, isSelectCommentModal: isSelectCommentModal, showAskSupport: showAskSupport });
    }

    onAnswerClick() {
        Event("Answer Clicked", "User clicked answer button in comments modal", "Answer Clicked");
        var answer = this.state.commentValue;
        var title = "";
        var message = "";
        var isCloseOnly = true;

        if (answer == null || answer.length < 2) {
            message = "Please type an answer before clicking the \"Answer Question\" button";
        }
        else {
            message = "A notification will be sent to the customer that their question is answered. Are you ready to submit?";
            isCloseOnly = false;
        }
        this.setState({ askSupportTitle: title, askSupportMessage: message, isCloseOnly: isCloseOnly, showAskSupport: !this.state.showAskSupport });
    }

    async askSupportConfirmed() {
        Event("Ask Support Clicked", "User clicked ask support button in comments modal", "Ask Support Clicked");
        var title = "";
        var skipUpdate = false;
        var success = true;
        var message = "";
        var successMessage = "";
        var errorMessage = ""; 
        var alert = this.props.commentAlert != null ? this.props.commentAlert : this.props.alert;
        var alertComment = this.props.alertComment !== undefined && this.props.alertComment !== null ?
            this.props.alertComment
            : { id: 0, assetId: parseInt(alert.assetId), alertTypeId: parseInt(alert.typeId), convoId: this.props.conversationId, hasNewComment: false, hasQuestion: false, hasAnswer: false };

        if (this.props.source === "AlertQuestions") {
            // Remove Question Asked Tag
            // Add Question Answered and Alert Partner Via Email Tags
            successMessage = "Answer Successfully Submitted";
            errorMessage = "Unable to submit Answer. Please check your connection and try again";
            
            if (alertComment.hasQuestion || !alertComment.hasAnswer) {
                alertComment.hasQuestion = false;
                alertComment.hasAnswer = true;
                // todo alert partner via email??
            }
            else {
                skipUpdate = true;
                message = "Question already answered.";
            }
        }
        else {
            // Apply Question Asked tag to alert
            successMessage = "The ProIQTM Advanced LifeWhere team has been notified. Please allow up to 24 hours for a response on your question";
            errorMessage = "Unable to notify LifeWhere team. Please check your connection and try again";

            if (!alertComment.hasQuestion) {
                alertComment.hasQuestion = true;
            }
            else {
                skipUpdate = true;
                message = "Question already asked and waiting for Support response. Please allow up to 24 hours for a response on your question"
            }
        }

        if (!skipUpdate) {
            this.props.updateAlertComment(alertComment);

            if (this.props.source === "AlertQuestions") {
                this.setState({ showAskSupport: false });
                this.listConvo();
                //this.props.forceReload(); //todo check if needed?
                this.props.updateAlertsList(alertComment);
                this.props.onClick();
            }
            else {
                //todo this for loop causes console warning - "Function declared in a loop contains unsafe references to variable(s) 'success', 'success'"
                for (let i in this.state.checkboxSelected) {
                    if (this.state.checkboxSelected[i]) {
                        var convo = this.state.currentConversation ? this.state.currentConversation : this.props.conversation;
                        var convoItemId = convo[i].conversationItemId;
                        var currentMessage = convo[i].message;
                        var message = "";
                        if (!currentMessage.includes(alert.type + " - ") || !currentMessage.includes("Question: ")) {
                            currentMessage = currentMessage.replace(alert.type + " - ", "");
                            currentMessage = currentMessage.replace("Question: ", "");
                            message = alert.type + " - Question: " + currentMessage;
                        }
                        var body = {
                            conversationItemId: convoItemId,
                            message: message
                        }

                        await AlertService.updateconversationitem(body)
                            .then(response => {
                                success = response.status === 200 ? true : false;
                                setTimeout(() => {
                                    success = false;
                                }, 3000);
                            })
                            .catch(e => {
                                console.log(e);
                                success = false;
                                setTimeout(() => {
                                    success = false;
                                }, 3000);
                            });
                    }
                }
            }

            // If the call to add tags fails at any point, we want to remove these tags from the alert.tagIds array:  
            if (!success) {
                title = "Error";
                message = errorMessage;
                if (this.props.source === "AlertQuestions") {
                    // Remove email partner and question answered tags because they were not added successfully
                    var emailIndex = alert.tagIds.indexOf(TagIdsEnum.AlertPartnerViaEmail);
                    alertComment.hasAnswer = false;
                    //todo email ?

                    // Add back Question Asked tag since it wasn't successfully removed  
                    alertComment.hasQuestion = true;
                }
                else {
                    alertComment.hasQuestion = false;
                }
                this.props.updateAlertComment(alertComment);
            }
            else if (this.props.source !== "AlertQuestions") {
                message = successMessage;
                this.setState({ askSupportTitle: title, askSupportMessage: message, isCloseOnly: true, showAskSupport: true, returnToAlerts: true, isSelectCommentModal: false });
            }
        }
        else {
            this.setState({ askSupportTitle: title, askSupportMessage: message, isCloseOnly: true, showAskSupport: true });
        }
    }

    generateCheckboxSelected() {
        var convo = this.state.currentConversation ? this.state.currentConversation : this.props.conversation;
        let checkObj = [];
        for (let i in convo) {
            checkObj[i] = false;
        }
        this.setState({ checkboxSelected: checkObj });
    }

    async updateAlert(alertList) {

    }

    getCheckbox(index) {
        var convo = this.state.currentConversation ? this.state.currentConversation : this.props.conversation;
        var commentEmail = convo[index].email;
        let isCurrentUserComment = commentEmail === this.props.userName;
        let checkbox = this.state.isSelectCommentModal ?
            <input style={{ float: "right", marginRight: '50px', marginTop: '5px' }} type="checkbox" checked={this.state.checkboxSelected[index]} id={index} onChange={this.changeCheckbox} hidden={!isCurrentUserComment} disabled={!isCurrentUserComment}/>
            : null;

        return checkbox;
    }

    changeCheckbox(e) {
        let tempCheckboxes = [...this.state.checkboxSelected];
        let checked = tempCheckboxes[e.target.id];
        tempCheckboxes[e.target.id] = !checked;

        this.setState({ checkboxSelected: tempCheckboxes });
    }

    getBackground(index) {
        let color = this.state.isSelectCommentModal && this.state.checkboxSelected[index] ? "cornflowerBlue" : "white";
        return color;
    }

    onFormSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.source === "AlertQuestions") {
            this.onAnswerClick();
        }
        else {
            this.listConvo();
        }
        // send state to server with e.g. `window.fetch`
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (this.props.source === "AlertQuestions") {
                this.onAnswerClick();
            }
            else {
                this.listConvo();
            }
        }
    }

    async addComment(conversationId) {
        Event("Comment added to alert", "User added comment to alert in comments modal", "Comment Added");
        var title = "";
        var skipUpdate = false;
        var success = true;
        var message = "";
        var successMessage = "";
        var errorMessage = "";
        var alert = this.props.commentAlert != null ? this.props.commentAlert : this.props.alert;
        var alertComment = this.props.alertComment !== undefined ?
            this.props.alertComment
            : { id: 0, assetId: parseInt(alert.assetId), alertTypeId: parseInt(alert.typeId), convoId: null, hasNewComment: false, hasQuestion: false, hasAnswer: false };

        if (alertComment.convoId !== parseInt(conversationId))
            alertComment.convoId = parseInt(conversationId);

        // Apply New Comment tag to alert
        successMessage = "Comment added.To push comment to the ProIQTM Advanced LifeWhere team, click the 'Ask Support' button.";
        errorMessage = "Unable to add comment";

         if (!alertComment.hasNewComment) {
            alertComment.hasNewComment = true;
            //this.props.updateAlertComment(alertComment);
            
            await CommentsService.updatecomment(alertComment)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        console.log("Succesfully added newComment to assetId : " + alert.assetId + " for alertTypeId: " + alert.t);
                        success = true;
                        this.props.updateAlertComment(alertComment, false);
                    }
                    else {
                        console.log("Failed to add newComment to assetId: " + alert.assetId);
                        success = false;
                    }
                    setTimeout(() => {
                        success = false;
                    }, 3000);
                }).
                catch(e => {
                    console.log(e);
                    success = false;
                    setTimeout(() => {
                        success = false;
                    }, 3000);
                });

            if (!success) {
                title = "Error";
                message = errorMessage;
                alertComment.hasNewComment = false;
                //this.props.updateAlertComment(alertComment);
            }
            else {
                message = successMessage;
            }
        }
    }

    async listConvo(convo) {
        convo = {};
        var alert = this.props.commentAlert != null ? this.props.commentAlert : this.props.alert;
        var genisisId = this.props.commentType === "ALERT" ? alert.assetId : this.props.job.caseId.toString();
        var convoType = this.props.commentType === "ALERT" ? ConversationTypeEnum.AssetComment : ConversationTypeEnum.OpportunityComment;
        var typeString = alert.type + " - ";

        if (this.props.conversation != null && this.props.conversation[0] != null && this.props.conversation[0] != undefined) {
            convo = {
                conversationId: this.props.conversation[0].conversationId,
                conversationType: convoType,
                genesisId: genisisId, 
                message: typeString + this.state.commentValue,
                private: (this.props.userOrg === ADMIN_ORG ? this.state.isInternalComment : false)
            };
        }
        else if (this.state.currentConversation != null && this.state.currentConversation[0] != null && this.state.currentConversation[0] != undefined) {
            convo = {
                conversationId: this.state.currentConversation[0].conversationId,
                conversationType: convoType,
                genesisId: genisisId, 
                message: typeString + this.state.commentValue,
                private: (this.props.userOrg === ADMIN_ORG ? this.state.isInternalComment : false)
            };
        }
        else if (this.props.conversationId != null && this.props.conversationId !== undefined) {
            convo = {
                conversationId: this.props.conversationId,
                conversationType: convoType,
                genesisId: genisisId, 
                message: typeString + this.state.commentValue,
                private: (this.props.userOrg === ADMIN_ORG ? this.state.isInternalComment : false)
            };
        }
        else {
            convo = {
                conversationType: convoType,
                genesisId: genisisId, 
                message: typeString + this.state.commentValue,
                private: (this.props.userOrg === ADMIN_ORG ? this.state.isInternalComment : false)
            };
        }

        await AlertService.addconversationitem(convo)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false && response.data?.length > 0) {
                    this.setState({ newComment: convo, commentsAdded: (this.state.commentsAdded + 1) }, async () => {
                        if (this.props.userOrg === ADMIN_ORG ? !this.state.isInternalComment : true) {
                            await this.addComment(this.state.newComment.conversationId);
                        }
                        
                        await AlertService.getconversationlist(response.data[0]?.conversationId)
                            .then(response2 => { 
                                let currentData = response2.data; //this.props.source == "TriageAlerts" ? response2.data : null;
                                if (currentData !== null) {
                                    currentData = currentData.filter(c => c.message.includes(alert.type + " - "));
                                }
                                this.setState({
                                    currentConversation: currentData, commentValue: ""
                                }, () => {
                                    this.props.addConversationToAsset(response.data[0].conversationId, convo.genesisId); // todo needed?
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    onCommentChange(e) {
        const comment = e.target.value;

        this.setState({ commentValue: comment });
    }

    getTime(time) {
        var d = new Date(time);
        //var hr = d.getHours() < 10 ? "0" : "";
        //var min = d.getMinutes() < 10 ? "0" : "";

        var date = d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

        if (time === "" || time == null)
            date = "";

        return (date);
    }

    formatDateHeader(date) {
        var weekday = date.toLocaleDateString('en-us', { weekday: 'short' });
        //var month = date.getMonth() + 1;
        var monthStr = date.toLocaleDateString('en-us', { month: 'short' });

        var dateString = weekday + ", " + monthStr + " " + date.getDate() + ", " + date.getFullYear();
        var headerObj = (<div className="chat-date-header"><span><b>{dateString}</b></span></div>);

        return headerObj;
    }

    getDateHeader(comment, index, commentList) {
        //var newDate = false;
        var date = null;
        var i = 0;
        var returnValue = null;
 
        var time = new Date(comment.timestamp + "Z");
        //var month = time.getMonth() + 1;

        if (index === 0) {
            return this.formatDateHeader(time);
        }
        else {
            commentList.forEach((c) => {
                var cDate = new Date(c.timestamp + "Z");
                //First item in convo, set the first date
                if (i === 0) {
                    date = cDate;
                }
                //Not First item, and month/day are not matching the previous known date 
                else if (date.getMonth() !== cDate.getMonth() || date.getDay() !== cDate.getDay()) {
                    // if it's the current comment, ADD DATE HEADING
                    if (c.conversationItemId === comment.conversationItemId) {
                        returnValue = this.formatDateHeader(time);
                    }
                    // Otherwise, set the new date 
                    else {
                        date = cDate;
                    }
                }
                //reached current comment being mapped, and there's nothing to do 
                else if (c.conversationItemId === comment.conversationItemId) {
                    return;
                }
                i += 1;
            });
            return returnValue;
        }
    }

    getMessage(type, message) {
        if (type !== null && type !== undefined) {
            message = message.replace(type + ' - ', '');
        }
        return message;
    }

    handleCheckbox() {
        this.setState({ isInternalComment: !this.state.isInternalComment});
    }

    renderConversation() {
        let commentList = this.state.currentConversation ? this.state.currentConversation : this.props.conversation;
        var alert = this.props.commentAlert != null ? this.props.commentAlert : this.props.alert;
        //var currentDate = "";
        let convo = commentList ?
            <ul style={{ listStyle: "none" }}>
                {commentList.map((comment, index) =>
                    [
                        <li key={index} style={{ padding: "8px 16px" }}>
                            <div>{this.getDateHeader(comment, index, commentList)}</div>
                        <div className="flex-spread flex-spread-normal" style={{ position: "relative" }}>
                            <div style={{ display: "flex", alignItems: "center", alignSelf: "flex-start" }}>
                                    <div style={{ paddingRight: "10px" }}>
                                        <div className="image-profile">
                                            {comment.firstName.charAt(0) + comment.lastName.charAt(0)}
                                        </div>
                                    </div>
                                <div style={{ fontWeight: "bold", paddingRight:"8px"}}>
                                     {comment.firstName + " " + comment.lastName}
                                </div>
                                <span>
                                        <span style={{ fontSize: "12px", color: "rgb(119, 119, 119)" }}>{this.getTime(comment.timestamp + "Z")}</span>
                                </span>
                                <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                                        <span style={{ color: "#0033a0", fontSize: "12px" }}>{comment.private === "true" ? "[internal]" : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingLeft: '40px', marginTop: '8px', textAlign: "left", backgroundColor: this.getBackground(index)}} >
                            <div>
                                {this.getMessage(alert.type, comment.message)}
                                {this.getCheckbox(index)}
                            </div>
                        </div>
                    </li>
                ])}
        </ul>
        : <div></div>;

        return (
            <div>
                {convo}
            </div>
            );
    }

    render() {
        let convo = this.renderConversation();

        let internalCommentCheck = this.props.userOrg === ADMIN_ORG && !this.state.isSelectCommentModal ?
            <div style={{ textAlign: "center", padding: "0px 0px 0px 0px" }}>
                <input style={{ color: "black" }} type="checkbox" value="internal comment" checked={this.state.isInternalComment} id="gdo" onChange={this.handleCheckbox} />
                &nbsp; Internal Comment
            </div>
            : <div></div>

        if (!this.props.show) {
            return null;
        }
        else {
            //var commentValue = "";
            let title = this.state.isSelectCommentModal ? "Select Comment(s)" : "Comments";
            let secondaryBtnText = this.state.isSelectCommentModal ? "Cancel" : "Close";

            var headerTxt = this.props.commentType === "ALERT" ? `Comments on ${this.props.alert.type} Alert` : `Comments on ${this.props.job.subject} Job` ;     
            var primaryBtn = this.props.source === "AlertQuestions" ? null
                 : this.state.isSelectCommentModal ?
                    <button className="primary-btn btn-small" onClick={this.askSupportConfirmed}>Submit</button>
                    :
                    <button className="primary-btn btn-small" onClick={this.onAskSupportClick}>Ask Support</button>

            var commentInput = this.state.isSelectCommentModal ? null :
                this.props.source === "AlertQuestions" ?
                    <form onSubmit={this.onFormSubmit} >
                        <textarea rows="2" cols="90" placeholder="Add comment" value={this.state.commentValue} onChange={this.onCommentChange} onKeyDown={this.onKeyDown} style={{ marginRight: "10px" }} />
                        <button className="answer-btn" type="submit" style={{ height: "54px", width: "75px", borderRadius: "20px" }} >Answer Question</button>
                        <br />
                    </form>
                    :
                    <form onSubmit={this.onFormSubmit} style={{ display: 'flex', marginLeft: '3em' }} >
                        <textarea rows="2" cols="90" placeholder={this.props.userOrg === ADMIN_ORG && this.state.isInternalComment ? "Add internal comment" : "Add comment"} value={this.state.commentValue} onChange={this.onCommentChange} onKeyDown={this.onKeyDown} style={{ marginRight: "10px", border: '1px solid #dee2e6', borderRadius: '.3em', padding: '5px' }} />
                        <button className="btn-large primary-btn" type="submit" style={{ height: "50px", width: "60px", margin: '5px', borderRadius: '12px' }} >Post</button>
                        <br />
                    </form>

            return (
                <div>
                    <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                        <div className="modal-wrapper"
                            style={{
                                transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                                opacity: this.props.show ? '1' : '0'
                            }}>

                            <div className="modal-header">
                                <h3>{title}</h3>
                                <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onModalButtonClick(); }}>×</span>
                            </div>

                            <div className="modal-body">
                                <br />
                                <p style={{ fontSize: "20px", fontWeight: "600" }}>
                                    {headerTxt}
                                </p>
                                <div>
                                    {convo}
                                </div>
                                {commentInput}
                                {internalCommentCheck}
                            </div>
                            <div className="modal-footer">
                                <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onModalButtonClick(); }}>{secondaryBtnText}</button>
                                {primaryBtn}
                            </div>
                        </div>
                    </div>
                    <AskSupportModal show={this.state.showAskSupport} isCloseOnly={this.state.isCloseOnly} onYes={this.askSupportConfirmed} onClick={this.onCancel} message={this.state.askSupportMessage} title={this.state.askSupportTitle}></AskSupportModal>
                </div>
            );
        }
    }
}

export default CommentsModal;
