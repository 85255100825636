/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/login";
const tokenKey = 'token';
const loginService = {
    login: async (user) => {
        const response = await http.post(`${controller}`, user);
        localStorage.setItem(tokenKey, response.data);
        return response.data;
    },
    logout: async () => {
        const response = await http.post(`${controller}/logout`, null);
        localStorage.setItem(tokenKey, null);
        return response.data;
    }
};
export default loginService;