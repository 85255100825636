/*

Resideo/LifeWhere
Copyright (C) 2018-2023 Resideo/LifeWhere

mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import { PageView, ModalView, Event } from "../GoogleAnalytics";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Snackbar from '@material-ui/core/Snackbar';
import Select from 'react-select';
//import PartnerService from "../../services/partner.service";
import RegisterUserService from "../../services/registeruser.service";
//import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
//import AddMultipleEmailAddresses from "../AccountManagement/Account/AddMultipleEmailAddresses"; 
import makeAnimated from "react-select/animated";
import HomeownerConfirmModal from './HomeownerConfirmModal';
import { jsHomeowner } from '../../componentObjects';

const animatedComponents = makeAnimated();

const STATES = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const validEmailEnds = ["com", "net", "gov", "org", "edu"];

const billingOptions = [
    {
        value: 0,
        label: "1 Year"
    },
    {
        value: 1,
        label: "2 Years"
    },
    {
        value: 2,
        label: "3 Years"
    }
];

class InviteHomeowner extends Component {
    static displayName = InviteHomeowner.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.resetForm = this.resetForm.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeConfirmEmail = this.onChangeConfirmEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeNickname = this.onChangeNickname.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeZip = this.onChangeZip.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeBillingPlan = this.onChangeBillingPlan.bind(this);

        this.handleInviteHomeowner = this.handleInviteHomeowner.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateConfirmEmail = this.validateConfirmEmail.bind(this);

        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.onInvite = this.onInvite.bind(this);

        this.state = {
            loading: false,
            showMessage: false,
            message: "",
            emailMessage: "",
            validated: false,
            validEmail: true,
            confirmEmailsMatch: true,
            nicknameChanged: false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            newHomeowner: {
                firstName: "",
                lastName: "",
                email: "",
                confirmEmail: "",
                phoneNumber: "",
                homeNickname: "",
                locationName: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                country: "USA",
                parentOrganizationId: this.props.currentPartnerId,
                company: this.props.loggedInCustomerOrganization,
                billingPlanId: this.props.loggedInCustomerOrganization == "LifeWhere" ? (this.props.attributeList?.find(a => (a.name == "RentalAgreementCustomer" && a.organizationId == this.props.currentPartnerId))?.value != undefined) ? parseInt(this.props.attributeList?.find(a => (a.name == "RentalAgreementCustomer" && a.organizationId == this.props.currentPartnerId))?.value) : -1 : this.props.rentalAgreementCustomerValue
            },
            homeownerObj: jsHomeowner,
            billingPlan: null,//this.props.billingOptions[0]
            isBillableforParter: this.props.loggedInCustomerOrganization == "LifeWhere" ? this.props.attributeList?.find(x => (x.name == "IsBillable" && x.organizationId == this.props.currentPartnerId))?.value == "1" ? true : false : false

        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        let offersMonitoringOnly = !!["Service", "Monitoring"].every(item => this.props.attributeList?.find(x => (x.name === "ContractType" && x.organizationId === this.props.currentPartnerId))?.value.split(',').includes(item));

        if (offersMonitoringOnly && !billingOptions.some(x => x.value === 3)) {
            billingOptions.push({
                value: 3,
                label: "Monitoring Only"
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    resetForm() {
        this.setState({
            loading: false,
            showMessage: false,
            message: "",
            emailMessage: "",
            validated: false,
            validEmail: true,
            confirmEmailsMatch: true,
            newHomeowner: {
                firstName: "",
                lastName: "",
                email: "",
                confirmEmail: "",
                phoneNumber: "",
                homeNickname: "",
                locationName: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                country: "USA",
                parentOrganizationId: this.props.currentPartnerId,
                company: this.props.loggedInCustomerOrganization,
                billingPlanId: -1
            },
            billingPlan: null 
        })
    }

/* input handlers */

    onChangeFirstName(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    firstName: newValue.trim()
                },
                homeownerObj: {
                    ...prevState.homeownerObj,
                    firstName: newValue.trim()
                }
            };
        });
    }

    onChangeLastName(e) {
        const newValue = e.target.value;
        let nickname = this.state.nicknameChanged ? this.state.newHomeowner.homeNickname : newValue.trim() + " Home"; //this.state.newHomeowner.homeNickname === "" ? newValue.trim() + " Home" : this.state.newHomeowner.homeNickname

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    lastName: newValue.trim(),
                    homeNickname: nickname,
                    locationName: nickname
                },
                homeownerObj: {
                    ...prevState.homeownerObj,
                    lastName: newValue.trim(),
                    description: nickname,
                    locationName: nickname
                }
            };
        });
    }

    onChangeEmail(e) {
        const newValue = e.target.value;
        let isValid = this.validateEmail(newValue);

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    email: newValue.trim()
                },
                homeownerObj: {
                    ...prevState.homeownerObj,
                    email: newValue.trim()
                },
                validEmail: isValid
            };
        });
    }

    onChangeConfirmEmail(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    confirmEmail: newValue.trim()
                }
            };
        });
    }

    onChangePhone(e) {
        const newValue = e.target.value;
        let regex = /^[0-9#()\-_.+]*$/; // 0-9 #()+-_
       
        if(regex.test(newValue) && newValue.length <= 16 )
        { 
            this.setState(function (prevState) {
                return {
                    newHomeowner: {
                        ...prevState.newHomeowner,
                        phoneNumber: newValue.trim()
                    },
                    homeownerObj: {
                        ...prevState.homeownerObj,
                        phoneNumber: newValue.trim()
                    }
                };
            });
        }
    }

    onChangeNickname(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                nicknameChanged: true,
                newHomeowner: {
                    ...prevState.newHomeowner,
                    homeNickname: newValue.trim(),
                    locationName: newValue.trim()
                },
                homeownerObj: {
                    ...prevState.homeownerObj,
                    description: newValue.trim(),
                    locationName: newValue.trim()
                }
            };
        });
    }

    onChangeAddress(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    address: newValue//.trim()
                },
                homeownerObj: {
                    ...prevState.homeownerObj,
                    address: newValue//.trim()
                }
            };
        });
    }

    onChangeCity(e) {
        const newValue = e.target.value;
        let regex = /^[A-Za-z]*$/;

        if (regex.test(newValue)) {
            this.setState(function (prevState) {
                return {
                    newHomeowner: {
                        ...prevState.newHomeowner,
                        city: newValue.trim()
                    },
                    homeownerObj: {
                        ...prevState.homeownerObj,
                        city: newValue.trim()
                    }
                };
            });
        }
    }

    onChangeState(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    state: newValue
                }
            };
        });
    }

    onChangeZip(e) {
        const newValue = e.target.value;
        let regex = /^[0-9]*$/;

        if (regex.test(newValue)) {
            this.setState(function (prevState) {
                return {
                    newHomeowner: {
                        ...prevState.newHomeowner,
                        zip: newValue.trim()
                    },
                    homeownerObj: {
                        ...prevState.homeownerObj,
                        zip: newValue.trim()
                    }
                };
            });
        }
    }

    onChangeCountry(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newHomeowner: {
                    ...prevState.newHomeowner,
                    country: newValue.trim()
                },
                homeownerObj: {
                    ...prevState.homeownerObj,
                    country: newValue.trim()
                }
            };
        });
    }

    onChangeBillingPlan = (selectedOption) => {
        let x = selectedOption;

        this.setState(function (prevState) {
            return {
                billingPlan: x,
                newHomeowner: {
                    ...prevState.newHomeowner,
                    billingPlanId: x.value
                }
            };
        });
    }

/* Submit Handlers */

    handleInviteHomeowner(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (this.validateForm() && form.checkValidity() === true) {
            let billing = (this.props.loggedInCustomerOrganization == "LifeWhere") ? this.state.isBillableforParter : (this.props.hasBilling);
            let contractTypes = this.props.attributeList?.find(x => (x.name === "ContractType" && x.organizationId === this.props.currentPartnerId))?.value;
            let isMonitoringOnly = contractTypes && contractTypes === "Monitoring";
            
            if (billing) {
                if (isMonitoringOnly) {
                    this.setState(function (prevState) {
                        return {
                            validated: true, showConfirmModal: true, newHomeowner: {
                                ...prevState.newHomeowner,
                                billingPlanId: 3
                            }
                        }
                    });
                }
                else {
                    this.setState({ validated: true, showConfirmModal: true }, () => {
                        ModalView("inviteHomeownerConfirm");
                    });
                }
            }
            else {
                this.setState(function (prevState) {
                    return {
                        validated: true, showConfirmModal: true, newHomeowner: {
                            ...prevState.newHomeowner,
                               billingPlanId: 999
                        }
                    }
                });
            }
           
        }
        else {
            console.log("form.checkValidity(): " + form.checkValidity());
            this.setState({
                validated: false,
                message: "Missing or Invalid inputs",
                showMessage: true
            });

            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 5000);
        }
    }

    openConfirmModal() {

    }

    showConfirmModal() {
        this.setState({ showConfirmModal: !this.state.showConfirmModal }, () => {
            if (this.state.showConfirmModal)
                ModalView("inviteHomeownerConfirm");
        });
    }

    onInvite() {
        Event("Invite Homeowner", "User invited homeowner", "Invite Homeowner");
        this.setState({ loading: true, showConfirmModal: false }, async () => {
            await RegisterUserService.createhomeowneraccount(this.state.newHomeowner) //.homeownerObj)
                .then(response => {
                    if (this._isMounted) {
                        if (response.data !== "Error" && response.data !== "")
                            this.setState({
                                loading: false,
                                showMessage: true,
                                message: "Successfully invited homeowner " + this.state.newHomeowner.firstName + " " + this.state.newHomeowner.lastName
                            }, () => {
                                setTimeout(() => {
                                    this.resetForm();
                                }, 5000);
                            });
                        else
                            this.setState({
                                loading: false,
                                showMessage: true,
                                message: "Failed to invite this homeowner"
                            });

                        setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 5000);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        loading: false,
                        message: "Failed to invite this homeowner",
                        showMessage: true
                    });

                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false
                        });
                    }, 5000);
                })
        });
    }

    validateForm() {
        var isValid = true;

        if (!this.validateEmail(this.state.newHomeowner.email))
            isValid = false;

        if (!this.validateConfirmEmail(this.state.newHomeowner.email, this.state.newHomeowner.confirmEmail))
            isValid = false;
        
        return isValid;
    }

    validateConfirmEmail(email, confirmEmail) {
        var emailMatch = false;

        if (email == null)
            email = "";
        if (confirmEmail == null)
            confirmEmail = "";

        if (email === confirmEmail)
            emailMatch = true;
        
        if (emailMatch) {
            this.setState({ confirmEmailsMatch: true });
            return true;
        }
        else {
            this.setState({ confirmEmailsMatch: false });
            return false;
        }
    }

    validateEmail(email) {
        let errorMsg = "";

        if (email == null)
            email = "";
        //todo console warning, there are two unnecessary escape characters in this string... - "Unnecessary escape character: \[  no-useless-escape"
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validConfig = regex.test(email);

        if (!validConfig)
            errorMsg = "Valid Email is required";
        else {
            let split = email.split('.');
            let end = split.pop();

            if (end === "" || end == null || end === undefined || !validEmailEnds.includes(end))
                errorMsg = "Email must end in .com, .net, .gov, .org, or .edu";
        }

        if (errorMsg !== "") {
            this.setState({ validEmail: false, emailMessage: errorMsg });
            return false;
        }
        else {
            this.setState({ validEmail: true, emailMessage: "" });
            return true;
        }
    }

    renderContent() {
        let billing = (this.props.loggedInCustomerOrganization == "LifeWhere") ? this.state.isBillableforParter : (this.props.hasBilling);
        let isServiceContractType = !!this.props.attributeList?.find(x => (x.name === "ContractType" && x.organizationId === this.props.currentPartnerId))?.value.split(',').includes("Service");
        
        let subscriptionForm = (billing && isServiceContractType) ?

            <div>
                <hr/> 
                <h5>Subscription</h5>
                <Row>
                    <Form.Group as={Col} md="6" controlId="billingPlan">
                        <Form.Label>Billing Plan</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={billingOptions}
                            onChange={this.onChangeBillingPlan}
                            placeholder="Select Billing Plan..."
                            value={billingOptions.filter((x) => x.value === this.state.newHomeowner.billingPlanId)}
                           
                        />
                        {/*value={billingOptions.filter((x) => x.value === this.state.newHomeowner.billingPlanId)}*/}
                        {/*<Form.Control.Feedback type="invalid">Billing Plan is required</Form.Control.Feedback>*/}
                    </Form.Group>
                </Row>
            </div>
            : null;

        return (
            <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>
                <Form noValidate validated={this.state.validated && this.state.validEmail && this.state.confirmEmailsMatch} onSubmit={this.handleInviteHomeowner}>
                    <h5>Homeowner Info</h5>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.firstName} onChange={this.onChangeFirstName} />
                            <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.lastName} onChange={this.onChangeLastName} />
                            <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="6" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="" value={this.state.newHomeowner.email} onChange={this.onChangeEmail} isValid={this.state.validated && this.state.validEmail} isInvalid={!this.state.validEmail} />
                            <Form.Control.Feedback type="invalid">{this.state.emailMessage/*Email is required*/}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="confirmEmail">
                            <Form.Label>Confirm Email</Form.Label>
                            <Form.Control required type="email" placeholder="" value={this.state.newHomeowner.confirmEmail} onChange={this.onChangeConfirmEmail} isInvalid={!this.state.confirmEmailsMatch} />
                            <Form.Control.Feedback type="invalid">Confirm Email is required and must match Email</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="6" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control required type="tel" placeholder="" value={this.state.newHomeowner.phoneNumber} onChange={this.onChangePhone} />
                            <Form.Control.Feedback type="invalid">Phone number is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <hr />

                    <h5>Home Address</h5>
                    <Row>
                        <Form.Group as={Col} md="4" controlId="homeNickname">
                            <Form.Label>Home Nickname</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.homeNickname} onChange={this.onChangeNickname} />
                            <Form.Control.Feedback type="invalid">Home Nickname is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.address} onChange={this.onChangeAddress} />
                            <Form.Control.Feedback type="invalid">Address is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.city} onChange={this.onChangeCity} />
                            <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        {/* <Form.Group as={Col} md="4" controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.state} onChange={this.onChangeState} />
                            <Form.Control.Feedback type="invalid">State is required</Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group as={Col} md="4" controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control as="select" value={this.state.newHomeowner.state} onChange={(e) => this.onChangeState(e)}>
                                {STATES.map((opt, i) =>
                                    (<option key={`state-${i}`} value={opt}>{opt}</option>)
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="zip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control required type="text" placeholder="" value={this.state.newHomeowner.zip} onChange={this.onChangeZip}/>
                            <Form.Control.Feedback type="invalid">Zip is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="" value={this.state.newHomeowner.country} onChange={this.onChangeCountry} />
                        </Form.Group>
                    </Row>

                    {subscriptionForm}

                    <br />
                    {(!this.state.isCustomerSupport) && (
                        <Button type="submit" style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}>Invite Homeowner</Button>)}
                </Form>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading
            ? <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        let contents = this.renderContent();

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h3 className="pageTitle" id="tabelLabel" >Invite Homeowner</h3>
                    {contents}
                    {loader}

                    <HomeownerConfirmModal show={this.state.showConfirmModal} homeowner={this.state.newHomeowner} onInvite={this.onInvite} onClick={this.showConfirmModal} />
                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(InviteHomeowner);