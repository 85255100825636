/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { PageView, Event } from "../GoogleAnalytics";
import DatePicker from 'react-datepicker';
import { withStyles } from '@material-ui/core/styles';
import DailyStatisticsService from "../../services/dailyStatistics.service";
import PartnerService from "../../services/partner.service";
import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Home from '../Home/Home';
import { roles } from "../../componentObjects";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const reprovisionClassId = "70";
const csvBaseName = "EnergyMetrics.csv";
const currentDate = new Date();
const month = currentDate.getMonth() + 1;
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const columnsToExclude = ['partnerOrgId', 'customerOrgId', 'homeId', 'hvacId', 'assetId', 'events'];


function modifyArray(arrayOfObjects) {
    const modifiedArray = arrayOfObjects.map(obj => {
        const modifiedObj = { ...obj }; 
        columnsToExclude.forEach(variable => {
            delete modifiedObj[variable];
        });
        return modifiedObj;
    });
    return modifiedArray;
}

function convertToCSV(objArray) {
    const array = [Object.keys(objArray[0])].concat(objArray.map(obj => Object.values(obj)));
    return array.map(row => row.join(',')).join('\n');
};

function downloadCSV(data, date, isPartnerAdmin) {
    Event("Download Data Clicked", "User clicked download data button in Partner Energy Metrics", "Download Data Clicked");
    var filenameWithDate = months[date.getMonth()] + "_" + csvBaseName;
    var modifiedData = isPartnerAdmin ? modifyArray(data) : data;
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(modifiedData));

    const link = document.createElement("a");
    link.setAttribute("href", csvContent); // Set csvContent directly
    link.setAttribute("download", filenameWithDate || "data.csv");
    document.body.appendChild(link);
    link.click();
};

class PartnerEnergyMetrics extends Component {
    static displayName = "Energy Metrics";
    static defaultSortCol = "CUSTOMER";
    _isMounted = false;

    constructor(props) {
        super(props);

        this.populateData = this.populateData.bind(this);
        this.calculateMonthlyData = this.calculateMonthlyData.bind(this);
        this.handleCloseDownloadMenu = this.handleCloseDownloadMenu.bind(this);
        this.handleDownloadMenu = this.handleDownloadMenu.bind(this);
        this.handleDownloadMenuSelect = this.handleDownloadMenuSelect.bind(this);

        this.state = {
            loading: false,
            monthlyDataObj: {},
            summarizedData: {},
            reprovisionAssetIdList: [],
            columnSort: {
                enabled: true,
                type: this.defaultSortCol,
                reverse: false
            },
            openDownloadMenu: false,
            dateSelected: currentDate,
            selected: -1,
            selectedDeviceName: ""
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.populateData(this.state.dateSelected);
    }

    populateData(date) {
        if (!this.props.isLoading) {
            this.setState({ loading: true }, () => {
                let yearSelected = date.getFullYear();
                let monthSelected = date.getMonth() + 1;
                var partnerId = this.props.currentPartnerId;
                PartnerService.searchpartnerassets(partnerId, reprovisionClassId).then(response => {
                    if (this._isMounted) {
                        var reprovisionAssetList = response.map(item => item.assetId);
                        this.setState({ reprovisionAssetIdList: reprovisionAssetList }, () => {
                            DailyStatisticsService.monthlypartnerdata(monthSelected, yearSelected, /*4479*/ partnerId).then(response2 => {
                                if (this._isMounted) {
                                    var monthlyData = response2;
                                    var calculatedData = this.calculateMonthlyData(response2.data);
                                    this.setState({ monthlyDataObj: response2.data, summarizedData: calculatedData, loading: false });
                                }
                                else {
                                    this.setState({ loading: false })
                                }
                            });
                        });
                    }
                    else {
                        this.setState({ loading: false })
                    }
                });
            })
        }

    }

    calculateMonthlyData(data) {
        // Initialize an empty object to store summarized data
        const summarizedData = {};

        // Iterate over each data entry
        data.forEach(entry => {
            // Extract necessary information
            const { deviceName, assetId, customerName, homeName, hvacName, assetType, runDate, amps, watts, kilowattHours, runs, cost, gasCost, timeRunning, alerts, equipmentAlerts, deviceAlerts } = entry;
            if (this.state.reprovisionAssetIdList.includes(assetId)) {
                return;
            }
            let date = new Date(runDate);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

            // If the device is not in the summarized data, initialize its entry
            if (!summarizedData[deviceName]) {
                summarizedData[deviceName] = {
                    customer: customerName,
                    homeName: homeName,
                    hvacName: hvacName,
                    assetType: assetType,
                    kilowattHours: 0,
                    amps: 0,
                    watts: 0,
                    runs: 0,
                    cost: 0,
                    gasCost: 0,
                    timeRunning: 0,
                    dayCount: 0,
                    alerts: 0,
                    deviceAlerts: 0,
                    equipmentAlerts: 0
                };
            }

            // Increment the count of entries for the device
            summarizedData[deviceName].dayCount++;

            // Sum up the values for each device
            summarizedData[deviceName].amps += amps;
            summarizedData[deviceName].watts += watts;
            summarizedData[deviceName].kilowattHours += kilowattHours;
            summarizedData[deviceName].runs += runs;
            summarizedData[deviceName].cost += cost;
            summarizedData[deviceName].gasCost += gasCost;
            summarizedData[deviceName].timeRunning += timeRunning;
            summarizedData[deviceName].alerts += alerts;
            summarizedData[deviceName].equipmentAlerts += equipmentAlerts;
            summarizedData[deviceName].deviceAlerts += deviceAlerts;
        });

        // Format Summary Data
        Object.keys(summarizedData).forEach(deviceName => {
            const { amps, watts, kilowattHours, cost, gasCost, count } = summarizedData[deviceName];
            summarizedData[deviceName].amps = amps.toFixed(2);
            summarizedData[deviceName].watts = watts.toFixed(2);
            summarizedData[deviceName].kilowattHours = kilowattHours.toFixed(2);
            summarizedData[deviceName].cost = cost.toFixed(2);
            summarizedData[deviceName].gasCost = gasCost.toFixed(2);
         });

        var summarizedDataList = Object.entries(summarizedData).map(([key, value]) => ({
            deviceName: key,
            ...value
        }));

        return summarizedDataList;
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        let data = this.state.summarizedData;
        let colData = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colData = this.columnSort(data, "CUSTOMER", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colData = this.columnSort(data, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colData = this.columnSort(data, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colData = this.columnSort(data, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, summarizedData: colData }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    columnSort(dataList, col, isReverse) {
        let colData = [];
        let data = dataList;

        if (col === "CUSTOMER") {
            colData = data.sort((a, b) => {
                const nameA = a.customer;
                const nameB = b.customer;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "HOME") {
            colData = data.sort((a, b) => {
                const nameA = a.homeName;
                const nameB = b.homeName;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "HVAC_SYSTEM") {
            colData = data.sort((a, b) => {
                const nameA = a.hvacName;
                const nameB = b.hvacName;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DEVICE") {
            colData = data.sort((a, b) => {
                const nameA = a.deviceName;
                const nameB = b.deviceName;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "ASSET_TYPE") {
            colData = data.sort((a, b) => {
                const nameA = a.assetType;
                const nameB = b.assetType;

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DAY") {
            colData = data.sort((a, b) => {
                const dateA = new Date(a.runDate);
                const dateB = new Date(b.runDate);
                return dateB - dateA;
            });
        }
        else if (col === "AMPS") {
            colData = data.sort((a, b) => {
                const numA = a.amps;
                const numB = b.amps;

                return numB - numA;
            });
        }
        else if (col === "KWH") {
            colData = data.sort((a, b) => {
                const numA = a.kilowattHours;
                const numB = b.kilowattHours;

                return numB - numA;
            });
        }
        else if (col === "TIME_RUNNING") {
            colData = data.sort((a, b) => {
                const numA = a.timeRunning;
                const numB = b.timeRunning;

                return numB - numA;
            });
        }
        else if (col === "ELECTRIC_COST") {
            colData = data.sort((a, b) => {
                const numA = a.cost;
                const numB = b.cost;

                return numB - numA;
            });
        }
        else if (col === "GAS_COST") {
            colData = data.sort((a, b) => {
                const numA = a.gasCost;
                const numB = b.gasCost;

                return numB - numA;
            });
        }
        else if (col === "ALERTS") {
            colData = data.sort((a, b) => {
                const numA = a.alerts;
                const numB = b.alerts;

                return numB - numA;
            });
        }
        else if (col === "RUNS") {
            colData = data.sort((a, b) => {
                const numA = a.runs;
                const numB = b.runs;

                return numB - numA;
            });
        }

        if (isReverse) {
            colData = colData.reverse();
        }

        return colData;
    }

    handleDownloadMenu(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openDownloadMenu: !this.state.openDownloadMenu
        });
    }

    handleCloseDownloadMenu() {
        this.setState({
            openDownloadMenu: false
        })
    }

    handleDownloadMenuSelect(isMonthSummary = false) {
        this.setState({ openDownloadMenu: false }, () => {
            var isPartnerAdmin = this.props.roles.includes(roles.partnerAdmin);
            if (isMonthSummary) {
                downloadCSV(this.state.summarizedData, this.state.dateSelected, isPartnerAdmin)
            }
            else {
                downloadCSV(this.state.monthlyDataObj, this.state.dateSelected, isPartnerAdmin)
            }
        });

    }

    onDeviceClick(index, deviceName) {
        Event("Energy Metrics Device Clicked", "User clicked a device in Partner Energy Metrics", "Energy Metrics Device Clicked");
        let x = index;
        if (this.state.selected == x) {
            this.setState({ selected: -1, alertMsgObj: {} });
        }
        else {
            var device = deviceName;
            this.setState({
                selected: x,
                selectedDeviceName: device
            });
        }
    }

    getExpandContent(index, deviceName) {
        var deviceContent = this.state.monthlyDataObj.filter(row => row.deviceName === deviceName);
        console.log("here");

        return (
            <tr className="expanded" key={`expandedContent-${index}`} style={{ backgroundColor: 'white !important' }}>
                <td colSpan={10} style={{ color: 'unset' }}>
                    <DeviceEnergyMetricsTable deviceData={deviceContent} iconFunction={this.iconFunction} formatSeconds={this.formatSeconds} convertWattToKwh={this.convertWattToKwh} columnSort={this.columnSort}/>
                </td>
            </tr>
        );
    }

    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            var name = split[0].replace("?", "'");

            const indexOfSlash = name.indexOf(' / ');
            if (indexOfSlash !== -1) {
                name = name.substring(indexOfSlash + 3);
            }
            return name;
        }
        else {
            return "";
        }
    }

    formatSeconds(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours} hours, ${minutes} minutes, ${remainingSeconds} seconds`;
    }

    convertWattToKwh(watts) {
        var kwh = (watts / 1000) * 24;
        return kwh;
    }

    iconFunction(columnName, columnSort) {
        let arrowIcon = columnSort.enabled ?
            (columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let icon = columnSort.enabled && columnSort.type === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return icon;
    }

    renderDatePicker() {

        const handleChange = async date => {
            this.setState({ dateSelected: date, loading: true });
            await this.populateData(date);
        };

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ marginLeft: '10px' }}>Data From:</div>
                <DatePicker
                    customInput={<input className="txt-detail" style={{ minWidth: '200px', width: '15%', textAlign: "center" }} />}
                    selected={this.state.dateSelected}
                    onChange={handleChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    popperPlacement="auto"
                    popperProps={{
                        positionFixed: true
                    }}
                />
            </div>
        );
    }

    renderTable() {
        let summaryData = this.state.summarizedData;
        //let deviceData = this.state.columnAltered ? this.state.columnData : this.props.deviceData;

        let summaryContent = summaryData.length ?
            <tbody>
                {summaryData.map((item, index) =>
                    [<tr className={this.state.selected == index ? "alerts-table-selected alerts-table-row" : "alerts-table-row"} key={item.deviceName} data={index} value={index} onClick={() => this.onDeviceClick(index, item.deviceName)}>
                        <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                            {item.customer}
                        </td>
                        <td>{this.getPrettyName(item.homeName)}</td>
                        <td>{this.getPrettyName(item.hvacName)}</td>
                        <td>{item.deviceName}</td>
                        <td>{item.assetType}</td>
                        <td>{item.amps}</td>
                        <td>{item.kilowattHours}</td>
                        <td>{this.formatSeconds(item.timeRunning.toFixed(0))}</td>
                        <td>{item.runs}</td>
                        <td>${item.cost}</td>
                        <td>${item.gasCost}</td>
                        <td className="alerts-td-right">
                            {this.state.selected == index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                        </td>
                    </tr>,
                        this.state.selected == index && (this.getExpandContent(index, item.deviceName))
                    ])}
            </tbody>
            : <tbody>
                <tr>
                    <td>{""}</td>
                </tr>
            </tbody>;


        return (
            <div style={{ margin: '20px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th style={{ padding: '0px 0px 0px 10px' }}>
                                    <div onClick={() => this.onColumnClick("CUSTOMER")}>
                                        <span style={{ cursor: 'pointer' }}>CUSTOMER</span>
                                        {this.iconFunction("CUSTOMER", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("HOME")}>
                                        <span style={{ cursor: 'pointer' }}>HOME</span>
                                        {this.iconFunction("HOME", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("HVAC_SYSTEM")}>
                                        <span style={{ cursor: 'pointer' }}>HVAC SYSTEM</span>
                                        {this.iconFunction("HVAC_SYSTEM", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("DEVICE")}>
                                        <span style={{ cursor: 'pointer' }}>DEVICE</span>
                                        {this.iconFunction("DEVICE", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("ASSET_TYPE")}>
                                        <span style={{ cursor: 'pointer' }}>ASSET TYPE</span>
                                        {this.iconFunction("ASSET_TYPE", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("AMPS")}>
                                        <span style={{ cursor: 'pointer' }}>AMPS</span>
                                        {this.iconFunction("AMPS", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("KWH")}>
                                        <span style={{ cursor: 'pointer' }}>KWH</span>
                                        {this.iconFunction("KWH", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("TIME_RUNNING")}>
                                        <span style={{ cursor: 'pointer' }}>TIME RUNNING</span>
                                        {this.iconFunction("TIME_RUNNING", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("RUNS")}>
                                        <span style={{ cursor: 'pointer' }}>RUNS</span>
                                        {this.iconFunction("RUNS", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("ELECTRIC_COST")}>
                                        <span style={{ cursor: 'pointer' }}>ELECTRIC COST</span>
                                        {this.iconFunction("ELECTRIC_COST", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("GAS_COST")}>
                                        <span style={{ cursor: 'pointer' }}>GAS COST</span>
                                        {this.iconFunction("GAS_COST", this.state.columnSort)}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
                            </tr>
                        </thead>
                        {summaryContent}
                    </table>
                </div>
            </div>
            );
    }

    render() {
        const { classes } = this.props;
        var isPartnerAdmin = this.props.roles.includes(roles.partnerAdmin);

        let downloadBtn =
            <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '0 30px', padding: '10px 0' }}>
                <div className="btn-right" style={{ padding: '25px 25px 25px 30px' }}>
                    <button className="primary-btn btn-large" style={{ width: 150 + 'px' }} onClick={this.handleDownloadMenu}>Download Data</button>     
               </div>
               <Menu
                    id="menu-download"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openDownloadMenu}
                    onClose={this.handleCloseDownloadMenu}
                >
                    <MenuItem onClick={() => { this.handleDownloadMenuSelect(true)}}>Month Summary</MenuItem>
                    <MenuItem onClick={() => { this.handleDownloadMenuSelect(false) }}>Daily Summary</MenuItem>
                </Menu>
            </div>

        let tableContents = this.state.loading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderTable();

        let datePicker = this.renderDatePicker();
        return (

            <div>
                <div >
                    <div className='infoPage'>
                        <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                            Partner Energy Metrics
                        </h2>
                        {downloadBtn}
                        {datePicker}
                        { tableContents }
                    </div>
                </div>
            </div>

        );
    }
}

class DeviceEnergyMetricsTable extends Component {
    static defaultSortCol = "DATE";
    constructor(props) {
        super(props);

        //this.populateData = this.populateData.bind(this);

        this.state = {
            loading: false,
            columnSort: {
                enabled: true,
                type: "DATE",
                reverse: false
            },
            columnAltered: false,
            columnData: [],
            selected: -1,
            selectedDeviceName: ""
        };
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        let data = this.props.deviceData;
        let colData = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colData = this.props.columnSort(data, "DATE", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colData = this.props.columnSort(data, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colData = this.props.columnSort(data, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colData = this.props.columnSort(data, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, columnData: colData }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    formatDate(dateString) {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        // Pad single-digit month and day with leading zero
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedMonth}-${formattedDay}-${year}`;
    }

    renderTable() {
        let deviceData = this.state.columnAltered ? this.state.columnData : this.props.deviceData;

        let deviceContent = deviceData.length ?
            <tbody>
                {deviceData.map((item, index) =>
                    [<tr className="alerts-table-row" key={item.runDate} data={index} value={index}>
                        <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                            {this.formatDate(item.runDate)}
                        </td>
                        <td>{item.amps.toFixed(2)}</td>
                        <td>{item.kilowattHours.toFixed(2)}</td>
                        <td>{this.props.formatSeconds(item.timeRunning.toFixed(0))}</td>
                        <td>${item.cost.toFixed(2)}</td>
                        <td>${item.gasCost.toFixed(2)}</td>
                        <td>{item.runs}</td>
                        <td className="alerts-td-right">{item.alerts}</td>
                    </tr>
                    ])}
            </tbody>
            : <tbody>
                <tr>
                    <td>{""}</td>
                </tr>
            </tbody>;


        return (
            <div style={{ margin: '20px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th style={{ padding: '0px 0px 0px 10px' }}>
                                    <div onClick={() => this.onColumnClick("DAY")}>
                                        <span style={{ cursor: 'pointer' }}>DAY</span>
                                        {this.props.iconFunction("DAY", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("AMPS")}>
                                        <span style={{ cursor: 'pointer' }}>AMPS</span>
                                        {this.props.iconFunction("AMPS", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("KWH")}>
                                        <span style={{ cursor: 'pointer' }}>KWH</span>
                                        {this.props.iconFunction("KWH", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("TIME_RUNNING")}>
                                        <span style={{ cursor: 'pointer' }}>TIME RUNNING</span>
                                        {this.props.iconFunction("TIME_RUNNING", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("ELECTRIC_COST")}>
                                        <span style={{ cursor: 'pointer' }}>ELECTRIC COST</span>
                                        {this.props.iconFunction("ELECTRIC_COST", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("GAS_COST")}>
                                        <span style={{ cursor: 'pointer' }}>GAS COST</span>
                                        {this.props.iconFunction("GAS_COST", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("RUNS")}>
                                        <span style={{ cursor: 'pointer' }}>RUNS</span>
                                        {this.props.iconFunction("RUNS", this.state.columnSort)}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}>
                                    <div onClick={() => this.onColumnClick("ALERTS")}>
                                        <span style={{ cursor: 'pointer' }}>ALERTS</span>
                                        {this.props.iconFunction("ALERTS", this.state.columnSort)}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {deviceContent}
                    </table>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        let deviceName = this.props.deviceData[0].deviceName;
        let downloadBtn =
            <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '0 30px', padding: '10px 0' }}>
                <div className="col" style={{ paddingRight: '30px' }}>
                    <button className="primary-btn btn-small" style={{ width: '150px' }} onClick={() => downloadCSV(this.props.deviceData, deviceName + "_EnergyMetrics.csv")}>Download Data</button>
                </div>
            </div>

        let tableContents = false ? //this.state.tableLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderTable();

        return (

            <div>
                {tableContents}
                {downloadBtn}
            </div>
        );
    }
}

export default withStyles(styles)(PartnerEnergyMetrics);
