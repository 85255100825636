/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import AccountService from "../../../services/account.service";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import UpdateImageModal from './UpdateImageModal';
import { PageView, Event, Timing, ModalView } from "../../GoogleAnalytics";

import { jsPartner, jsAccount } from "../../../componentObjects";

import memoize from "memoize-one";
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import AddMultipleEmailAddresses from './AddMultipleEmailAddresses.js';
import AddIntegration from '../../Integrations/AddIntegration';
import MuteAlertConfirmationModal from "../../ConfirmationModal/MuteAlertConfirmationModal";
import PartnerService from '../../../services/partner.service';
import CustomerService from '../../../services/customer.service';
import billingService from '../../../services/billing.service';
import AssetService from '../../../services/asset.service';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const jsUploadResults = {
    id: null,
    picture: null,
    data: null
};

class AccountManagement extends Component {
    static displayName = AccountManagement.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getAccount = this.getAccount.bind(this);
        this.getPartnerProps = this.getPartnerProps.bind(this);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDisplayName = this.onChangeDisplayName.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        // need on changes for logo.

        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeZip = this.onChangeZip.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeBilling = this.onChangeBilling.bind(this);
        this.onChangeContract = this.onChangeContract.bind(this);
        this.onChangeBillingDate = this.onChangeBillingDate.bind(this);
        this.onChangeCompUnits = this.onChangeCompUnits.bind(this);
        this.onChangeAcCutoff = this.onChangeAcCutoff.bind(this);
        this.onChangeGasPrice = this.onChangeGasPrice.bind(this);
        this.onChangeElectricPrice = this.onChangeElectricPrice.bind(this);
        this.onBlurPropertiesField = this.onBlurPropertiesField.bind(this);
        this.savePartnerProps = this.savePartnerProps.bind(this);

        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);

        this.openCancelModal = this.openCancelModal.bind(this);
        this.showCancelModal = this.showCancelModal.bind(this);
        this.openSaveModal = this.openSaveModal.bind(this);
        this.showSaveModal = this.showSaveModal.bind(this);

        this.onUpdateLogo = this.onUpdateLogo.bind(this);
        this.onUpdateIcon = this.onUpdateIcon.bind(this);

        this.openLogoModal = this.openLogoModal.bind(this);
        this.showLogoModal = this.showLogoModal.bind(this);
        this.openIconModal = this.openIconModal.bind(this);
        this.showIconModal = this.showIconModal.bind(this);

        this.updatePartner = this.updatePartner.bind(this);
        this.handleLogoChange = this.handleLogoChange.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);

        this.openSubscriptionModal = this.openSubscriptionModal.bind(this);
        this.showSubscriptionModal = this.showSubscriptionModal.bind(this);
        this.onUpgradeSubscription = this.onUpgradeSubscription.bind(this);

        this.onAddEmailClicked = this.onAddEmailClicked.bind(this);
        this.onDeleteEmailClicked = this.onDeleteEmailClicked.bind(this);
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.openalertMuteUntillModal = this.openalertMuteUntillModal.bind(this);
        this.onChangeMuteUntillDate = this.onChangeMuteUntillDate.bind(this);
        this.onSaveAlertMuteDate = this.onSaveAlertMuteDate.bind(this);
        this.asyntpartnerAttributes = this.asyntpartnerAttributes.bind(this);

        this.state = {
            currentAccount: jsAccount,
            currentPartner: jsPartner,
            uploadLogoResults: jsUploadResults,
            uploadIconResults: jsUploadResults,
            currentLogo: null,
            currentIcon: null,
            updateLogo: null,
            updateIcon: null,
            updateLogoPath: null,
            updateIconPath: null,
            logoBytes: [],
            iconBytes: [],
            logoFileStorage: {
                name: '',
                byteArray: [],
                binaryString: ''
            },
            iconFileStorage: {
                name: "",
                byteArray: [],
                binaryString: ''
            },
            logoUpdated: false,
            newLogoFile: null,
            iconUpdated: false,
            newIconFile: null,
            message: "",
            loading: false,
            propsLoading: false,
            partnerProps: {},
            showCancelConfirm: false,
            showSaveConfirm: false,
            showLogoUpdate: false,
            showIconUpdate: false,
            showSubscriptionUpgrade: false,
            modalMessage: "",
            modalTitle: "",
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            startTime: moment().date(1),
            selectedMutedDate: "",
            alertMuteDate:""
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.getAccount();
            this.asyntpartnerAttributes();
            this.getPartnerProps();
        /*if (this.state.loading === false) {
            this.setState({
                loading: true
            }, () => {
                this.getAccount(this.props.currentPartnerId);
            });              
        }*/
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //memPartner = memoize(this.getAccount);

    onChangeName(e) {
        const newName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    name: newName
                }
            };
        });
    }
    
     onChangeMuteUntillDate(e) {
        this.setState({
            selectedMutedDate: e
        });
     }
    onChangeDisplayName(e) {
        const newName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    company: newName
                }
            };
        });
    }

    onChangeDescription(e) {
        const newName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    description: newName
                }
            };
        });
    }

    onChangeAddress(e) {
        const newAddress = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    address: newAddress
                }
            };
        });
    }

    onChangeCity(e) {
        const newCity = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    city: newCity
                }
            };
        });
    }

    onChangeState(e) {
        const newState = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    state: newState
                }
            };
        });
    }

    onChangeZip(e) {
        const newZip = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    zip: newZip
                }
            };
        });
    }

    onChangePhone(e) {
        const newPhone = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    phone: newPhone
                }
            };
        });
    }

    onChangeBilling(e) {
        const newBilling = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    isBillable: newBilling
                }
            };
        });
    }

    onChangeContract(e) {
        const newContract = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    contractType: newContract
                }
            };
        });
    }

    onChangeBillingDate(e) {
        //const newValue = e.target.value;
        let newDate = moment(e).date(1).format("MM/DD/YYYY");

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    firstBillingDate: newDate
                }
            };
        });
    }

    onChangeCompUnits(e) {
        const newValue = e.target.value;
        if (parseInt(newValue) > 10)
            newValue = 10;
        else if (parseInt(newValue) < 0)
            newValue = 0;

        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    complementaryUnits: newValue
                }
            };
        });
    }

    onChangeAcCutoff(e) {
        let acCutoffTemp = e.target.value;
        acCutoffTemp = acCutoffTemp.replace(" °", "");
        acCutoffTemp = acCutoffTemp.replace("F", "");
        this.setState({ partnerProps: {
                ...this.state.partnerProps,
                acCutoffTemp: acCutoffTemp //parseInt(acCutoffTemp, 10)
            }
        });
    }

    onChangeElectricPrice(e) {
        let electricPrice = e.target.value;
        electricPrice = electricPrice.replace('$', '');
        this.setState({ partnerProps: {
                ...this.state.partnerProps,
                electricPrice: electricPrice //parseFloat(electricPrice).toFixed(2)
            }
        });
    }

    onChangeGasPrice(e) {
        let gasPrice = e.target.value;
        gasPrice = gasPrice.replace('$', '');
        this.setState({ partnerProps: {
                ...this.state.partnerProps,
                gasPrice: gasPrice //parseFloat(gasPrice).toFixed(2)
            }
        });
    }

    onBlurPropertiesField(e) {
        if (e.target.id === "electricPrice"){
            let electricPrice = e.target.value;
            electricPrice = electricPrice.replace('$', '');
            this.setState({ partnerProps: {
                    ...this.state.partnerProps,
                    electricPrice: parseFloat(electricPrice).toFixed(3)
                }
            });
        }
        else if (e.target.id === "gasPrice") {
            let gasPrice = e.target.value;
            gasPrice = gasPrice.replace('$', '');
            this.setState({ partnerProps: {
                    ...this.state.partnerProps,
                    gasPrice: parseFloat(gasPrice).toFixed(3)
                }
            });
        }
        else if (e.target.id === "acCutoffTemp"){
            let acCutoffTemp = e.target.value;
            acCutoffTemp = acCutoffTemp.replace(" °", "");
            acCutoffTemp = acCutoffTemp.replace("F", "");
            let acCutoffTempValue = parseInt(acCutoffTemp, 10);
            if (acCutoffTempValue > 100) {
                acCutoffTempValue = 100;
            }
            this.setState({ partnerProps: {
                    ...this.state.partnerProps,
                    acCutoffTemp: acCutoffTempValue
                }
            });
        }
    }

    showSubscriptionModal() {
        this.setState({
            showSubscriptionUpgrade: !this.state.showSubscriptionUpgrade
        }, () => {
            if (this.state.showSubscriptionUpgrade)
                ModalView("partnerAccount/subscriptionUpdate");
        });
    }

    openSubscriptionModal() {
        this.showSubscriptionModal();
    }

    onUpgradeSubscription(newSubId) {

    }
    openalertMuteUntillModal() 
    {
        this.setState({
            showMuteAlertConfirm : !this.state.showMuteAlertConfirm
        });
    }
    
     asyntpartnerAttributes() {
        CustomerService.getorgdetail(this.props.currentPartnerId)
            .then(response => {
                var acalertMuteDate = response?.data?.attributes?.find(x => x.name == "ACAlertMuteDate");
                if (acalertMuteDate != undefined) {
                    var muteDate = new Date(acalertMuteDate?.value).toLocaleDateString();
                    this.setState({
                        alertMuteDate: muteDate,
                        selectedMutedDate: new Date(acalertMuteDate?.value)
                    });
                } else {
                    this.setState({
                        alertMuteDate: "NA",
                        selectedMutedDate: new Date()
                    });
                }
            }).catch(e => {
                console.log(e)
            });
    }
    
    showCancelModal() {
        this.setState({
            showCancelConfirm: !this.state.showCancelConfirm
        }, () => {
                if (this.state.showCancelConfirm)
                    ModalView("partnerAccount/cancelConfirm");
        });
        window.scrollTo(0, 0);
    }

    openCancelModal() {
        this.setState({ modalMessage: "Are you sure you want to undo all changes?", modalTitle: "Undo Changes" }, () => {
            this.showCancelModal();
        });
    }

     onSaveAlertMuteDate() {
        const selectedmutedate = new Date(this.state.selectedMutedDate).toLocaleDateString();
        PartnerService.updatemuteuntilalert(this.props.currentPartnerId, selectedmutedate).then(response => {
                if (response === true) {
                    billingService.getpartnersubscriptions(this.props.currentPartnerId)
                      .then(response => { this.asyntpartnerAttributes(); }).catch (e => {
                          this.showTemporaryMessage( "AC Alert mute untill date is updated successfully");
                    });
                    Event("AC Alert Mute Update", "User clicked button to save changes AC Alert Mute Update", "AC Alert Mute Update Save Clicked");
                    this.setState({
                        showMuteAlertConfirm: !this.state.showMuteAlertConfirm
                    });
                }
                else {
                    this.showTemporaryMessage("Failed to update AC Alert mute untill date !");
                    this.setState({
                       showMuteAlertConfirm: !this.state.showMuteAlertConfirm
                    });
                }
            }
            ).catch(e => {
                this.showTemporaryMessage("Failed to update AC Alert mute untill date !");
             });

     }
    
    showSaveModal() {
        this.setState({
            showSaveConfirm: !this.state.showSaveConfirm
        }, () => {
                if (this.state.showSaveConfirm)
                    ModalView("partnerAccount/saveConfirm");
        });
        window.scrollTo(0, 0);
    }

    openSaveModal() {
        this.setState({ modalMessage: "Are you sure you want to save all changes?", modalTitle: "Save Changes" }, () => {
            this.showSaveModal();
        });
    }

    onSave() {
        this.updatePartner();
        this.setState({ showSaveConfirm: !this.state.showSaveConfirm });
    }

    onCancel() {
        var startTime = performance.now();
        this.setState({ loading: true });
        AccountService.getpartnerinfo(this.props.currentPartnerId)
            .then(response => {
                if (this._isMounted)                    
                    this.setState({
                        currentPartner: response.data, showCancelConfirm: !this.state.showCancelConfirm, loading: false, message: "Changes undone successfully!"
                    }, () => {
                            var elapsedTime = performance.now - startTime;
                            Timing("Cancelling Changes to Partner Account", "loading", elapsedTime, "Cancelling Changes to Partner Account");
                            Event("Partner Cancel", "User clicked button to cancel changes to partner account", "Partner Account Cancel Clicked");
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    showLogoModal() {
        this.setState({
            showLogoUpdate: !this.state.showLogoUpdate
        }, () => {
                if (this.state.showLogoUpdate)
                    ModalView("partnerAccount/logoUpdate");
        });
        window.scrollTo(0, 0);
    }

    openLogoModal() {
        this.setState({ modalMessage: "Choose new Logo to upload (PNG, JPG):", modalTitle: "Update Logo" }, () => {
            this.showLogoModal();
        });
    }

    showIconModal() {
        this.setState({
            showIconUpdate: !this.state.showIconUpdate
        }, () => {
                if (this.state.showIconUpdate)
                    ModalView("partnerAccount/iconUpdate");
        });
        window.scrollTo(0, 0);
    }

    openIconModal() {
        this.setState({ modalMessage: "Choose new Icon to upload (PNG, JPG):", modalTitle: "Update Icon" }, () => {
            this.showIconModal();
        });
    }

    onUpdateLogo() {
        Event("Partner Update Logo", "User uploaded new partner logo", "Update Partner Account Logo Clicked");
        AccountService.uploadimage(this.state.logoFileStorage)
            .then(response => {
                this.setState({
                    uploadLogoResults: response.data, showLogoUpdate: !this.state.showLogoUpdate
                }, () => {
                        this.setState(function (prevState) {
                            return {
                                logoUpdated: true,
                                currentPartner: {
                                    ...prevState.currentPartner,
                                    logo: this.state.uploadLogoResults.picture,
                                    logoId: this.state.uploadLogoResults.id,
                                    logoData: this.state.uploadLogoResults.data
                                }
                            };
                        });
                });
            })
    }

    handleLogoChange(file) {
        this.setState({ updateLogo: file, updateLogoPath: file.name });
        let reader = new FileReader();
        var fileByteArray = [];
        //var binaryString = "";
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                var arrayBuffer = e.target.result,
                    array = new Uint8Array(arrayBuffer);
                    //binaryString = String.fromCharCode.apply(null, array)
                //console.log(binaryString);

                for (var i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
            }
        }
        this.setState({ logoFileStorage: { name: file.name, byteArray: fileByteArray }, logoBytes: fileByteArray, newLogoFile: URL.createObjectURL(file) });
    }

    onUpdateIcon() {
        Event("Partner Update Icon", "User uploaded new partner icon", "Update Partner Account Icon Clicked");
        AccountService.uploadimage(this.state.iconFileStorage)
            .then(response => {
                this.setState({
                    uploadIconResults: response.data, showIconUpdate: !this.state.showIconUpdate
                }, () => {
                    this.setState(function (prevState) {
                        return {
                            iconUpdated: true,
                            currentPartner: {
                                ...prevState.currentPartner,
                                icon: this.state.uploadIconResults.picture,
                                iconId: this.state.uploadIconResults.id,
                                iconData: this.state.uploadIconResults.data
                            }
                        };
                    });
                });
            })
    }

    handleIconChange(file) {
        this.setState({ updateIcon: file, updateIconPath: file.name });
        let reader = new FileReader();
        var fileByteArray = [];
        //var binaryString = "";
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                var arrayBuffer = e.target.result,
                    array = new Uint8Array(arrayBuffer);
                    //binaryString = String.fromCharCode.apply(null, array);

                //console.log(binaryString);

                for (var i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
            }
        }
        this.setState({ iconFileStorage: { name: file.name, byteArray: fileByteArray }, iconBytes: fileByteArray, newIconFile: URL.createObjectURL(file) });
    }

    onAddEmailClicked = () => {
        Event("Add Email Clicked", "User clicked to Add Email to Partner Account", "Add Email Clicked");
        this.state.currentPartner.notificationEmailAddresses.push("");
        this.updateNotificationEmailAddresses(this.state.currentPartner.notificationEmailAddresses);       
    }

    onDeleteEmailClicked = (position) => {
        Event("Delete Email Clicked", "User clicked to Delete Email from Partner Account", "Delete Email Clicked");
        this.state.currentPartner.notificationEmailAddresses.splice(position, 1);
        this.updateNotificationEmailAddresses(this.state.currentPartner.notificationEmailAddresses);        
    }

    onEmailChanged = (e, value, position) => {
        Event("Email Changed", "User changed Email for Partner Account", "Email Changed");
        let newEmailAddresses = this.state.currentPartner.notificationEmailAddresses.slice();
        newEmailAddresses[position] = value;
        this.updateNotificationEmailAddresses(newEmailAddresses);       
    }

    updateNotificationEmailAddresses(emailAddresses) {
        this.setState(function (prevState) {
            return {
                currentPartner: {
                    ...prevState.currentPartner,
                    notificationEmailAddresses: emailAddresses
                }
            };
        });
    }

    async cleanseEmailAddresses() {
        if (this.state.currentPartner.notificationEmailAddresses.length > 0) {
            const cleansedEmails = [];

            this.state.currentPartner.notificationEmailAddresses.forEach(email => {
                if (email !== "") {
                    cleansedEmails.push(email.trim());
                }
            });        

            this.updateNotificationEmailAddresses(cleansedEmails);            
        }
    }

    updatePartner() {
        this.cleanseEmailAddresses().then(() => {
            AccountService.updateorg(this.state.currentPartner)
                .then(response => {
                    if (response.data === true) {
                        this.setState({
                            message: "Partner Account updated successfully!"
                        }, () => {
                            if (this.state.loading === false) {
                                this.setState({
                                    loading: true
                                }, () => {
                                    this.getAccount(this.props.currentPartnerId);
                                });
                            }
                        });
                        Event("Partner Update", "User clicked button to save changes to partner account", "Partner Account Save Clicked");
                    }
                    else {
                        this.showTemporaryMessage("Failed to update partner account!");
                    }
                }).catch(e => {
                    this.showTemporaryMessage("Failed to update partner account!");
                });
        }).catch(err => {
            this.showTemporaryMessage("Failed to update partner account!")
        });
    }

    showTemporaryMessage(message) {
        this.setState({
            message: message
        });

        setTimeout(() => {
            this.setState({
                message: ""
            });
        }, 3000);
    }

    getAccount() {
        if (!this.props.isLoading) {
            this.setState({ loading: true }, async () => {
                var startTime = performance.now();
                this.setState({ currentPartner: jsPartner, logoUpdated: false, iconUpdated: false }, async () => {
                    await AccountService.getpartnerinfo(this.props.currentPartnerId)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    currentPartner: response.data, loading: false
                                }, () => {
                                    var elapsedTime = performance.now() - startTime;
                                    Timing("Partner Account Loading", "loading", elapsedTime, "Partner Account Loading");
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                });
            })
        }
    }

    getPartnerProps() {
        if (!this.props.isLoading) {
            this.setState({ propsLoading: true }, async () => {
                var startTime = performance.now();
                this.setState({ partnerProps: {} }, async () => {
                    await PartnerService.getpartnerproperties(this.props.currentPartnerId)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    partnerProps: this.mapPartnerProps(response), propsLoading: false
                                }, () => {
                                    var elapsedTime = performance.now() - startTime;
                                    Timing("Partner Properties Loading", "loading", elapsedTime, "Partner Properties Loading");
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                });
            })
        }
    }

    mapPartnerProps(propsList) {
        let shadowTwinAssetId = propsList.find(obj => obj.name === "shadowTwinAssetId").valueRaw;
        let acCutoffTemp = propsList.find(obj => obj.name === "acCutoffTemp").valueRaw;
        let electricPrice = propsList.find(obj => obj.name === "electricPrice").valueRaw;
        let gasPrice = propsList.find(obj => obj.name === "gasPrice").valueRaw;

        let partnerProps = {
            shadowTwinAssetId: shadowTwinAssetId === null ? "" : shadowTwinAssetId,
            acCutoffTemp: acCutoffTemp === null ? 65 : parseInt(acCutoffTemp, 10).toFixed(0),
            electricPrice: electricPrice === null ? 0.00 : parseFloat(electricPrice).toFixed(3),
            gasPrice: gasPrice === null ? 0.00 : parseFloat(gasPrice).toFixed(3)
        }
        return partnerProps;
    }

    savePartnerProps()
    {
        var startTime = performance.now();
        let propertyUpdateObj = {
            assetId: parseInt(this.state.partnerProps.shadowTwinAssetId, 10),
            names: "acCutoffTemp|electricPrice|gasPrice",
            dataTypeIds: "1|1|1",
            values: `${this.state.partnerProps.acCutoffTemp}|${this.state.partnerProps.electricPrice}|${this.state.partnerProps.gasPrice}`
        }
        this.setState({ propsLoading: true }, async () => {
            await AssetService.updateProperties(propertyUpdateObj)
                .then(response => {
                    if (this._isMounted)
                        this.setState({propsLoading: false}, () => {
                            var elapsedTime = performance.now() - startTime;
                            Timing("Partner Properties Loading", "loading", elapsedTime, "Partner Properties Loading");
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    getSubLevel(subId) {
        let subLevel = "Unknown";
        switch (subId) {
            case '1':
                subLevel = "Pro-IQ Essentials";
                break;
            case '2':
                subLevel = "Pro-IQ Essentials Plus";
                break;
            case '3':
                subLevel = "Pro-IQ Advanced";
                break;
            case '4':
                subLevel = "Pro-IQ Command";
                break;
            case '5':
                subLevel = "Pro-IQ Protect";
                break;
            default:
                subLevel = "Default";
                break;
        }
        return subLevel;
    }

    renderAccount() {
        /*let tableContent =
            <tbody>
                <tr>
                    <td>name</td>
                    <td>desc</td>
                    <td>priority name</td>
                    <td>message</td>
                    <td>message details</td>
                </tr>
            </tbody>;*/
         let alertMuteUntillRow = this.props.isPartnerAdmin ?
            <div className="row no-gutters" style={{ display: 'flex' }}>
                <div className="col-sm-12 col-md-6" style={{ padding: '15px' }}>
                    <span>Silence AC Device Alerts Untill: <i>{this.state.alertMuteDate}</i></span>
                    <div className="row">
                        <div className="col">
                        </div>
                        <div className="col">
                            <button
                                type="button"
                                className="secondary-btn btn-small btn-right"
                                onClick={this.openalertMuteUntillModal}
                            >
                                Silence AC Device Alerts
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            : null;

        let subscriptionRow = this.props.isPartnerAdmin ?
            <div className="row no-gutters" style={{ display: 'flex' }}>
                <div className="col-sm-12 col-md-6" style={{ padding: '15px' }}>
                    <span>Subscription Level: <i>{this.getSubLevel(this.props.serviceTier)}</i></span>
                    <div className="row">
                        <div className="col">
                        </div>
                        <div className="col">
                            <button
                                type="button"
                                className="secondary-btn btn-small btn-right"
                                onClick={this.openSubscriptionModal}
                            >
                                Update Subscription Level
                                    </button>
                        </div>
                    </div>
                </div>
            </div>
            : null;

        let logoImage = this.state.logoUpdated ?
            <Image src={this.state.newLogoFile} style={{ height: 6 + "em", padding: 10 + "px" }} />
            : <Image src={this.state.currentPartner === null || this.state.currentPartner === undefined || this.state.currentPartner.logo == null ? require("../../../img/NoImage2.png") : "data:image/png;base64," + this.state.currentPartner.logoData} style={{ height: 6 + "em", padding: 10 + "px" }} />;

        let iconImage = this.state.iconUpdated ?
            <Image src={this.state.newIconFile} style={{ height: 6 + "em", padding: 10 + "px" }} />
            : <Image src={this.state.currentPartner === null || this.state.currentPartner === undefined || this.state.currentPartner.icon == null ? require("../../../img/NoImage2.png") : "data:image/png;base64," + this.state.currentPartner.iconData} style={{ height: 6 + "em", padding: 10 + "px" }} />;

        //show billing only if resideo, only admin can edit.
        let billingRow = this.props.roles.includes("ADMIN") || this.props.roles.includes("CUSTOMER SUPPORT") ?
            <div className="row no-gutters" style={{ display: 'flex' }}>
                <div className="col-sm-12 col-md-12">
                    <div className="white-container" style={{ minHeight: '100%' }}>
                        <h5 className="sectionTitle">Billing</h5>
                        <form className="input-container">
                            <div className="row no-gutters" style={{ marginBottom: '15px' }}>
                                <div className="col-sm-6 col-md-3">
                                    <label htmlFor="billing">Billing Status</label>
                                    <select
                                        className="form-control"
                                        id="description"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.isBillable !== null ? this.state.currentPartner.isBillable : "0"}
                                        onChange={this.onChangeBilling}
                                        disabled={this.props.roles.includes("ADMIN") ? false : true}
                                    >
                                        <option value="1">Billable</option>
                                        <option value="0">Not Billable</option>
                                    </select>
                                </div>
                                <div className="col-sm-6 col-md-3" style={{ paddingLeft: '15px'}}>
                                    <label htmlFor="description">Contract Type</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="radio" value="Service" name="contractType" onChange={e => this.onChangeContract(e)} checked={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.contractType === "Service" ? true : false} disabled={(this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.isBillable === "1") ? false : true} />
                                        &nbsp; Service Contract &nbsp;&nbsp;
                                        <input type="radio" value="Monitoring" name="contractType" onChange={e => this.onChangeContract(e)} checked={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.contractType !== "Service" ? true : false} disabled={(this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.isBillable === "1") ? false : true} />
                                        &nbsp; Monitoring Contract &nbsp;&nbsp;
                                    
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3">
                                    <label htmlFor="description">First Billing Date</label>
                                    <DatePicker
                                        customInput={<input className="txt-detail" /*disabled={this.state.disableGraphOptions}*/ style={{ minWidth: '200px', textAlign: "center" }} />}
                                        id="billingDate"
                                        name="billingDate"
                                        selected={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.firstBillingDate !== null && this.state.currentPartner.firstBillingDate !== "" ? moment(this.state.currentPartner.firstBillingDate).toDate() : this.state.startTime.toDate()}
                                        onChange={date => { this.onChangeBillingDate(date); }}
                                        popperPlacement="top-end"
                                        popperProps={{
                                            positionFixed: true
                                        }}
                                        disabled={(this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.isBillable === "1") ? false : true}
                                    />
                                </div>
                                <div className="col-sm-6 col-md-3">
                                    <label htmlFor="description">Complementary Units</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="compUnits"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.complementaryUnits !== null && this.state.currentPartner.complementaryUnits !== '' ? this.state.currentPartner.complementaryUnits : 0}
                                        onChange={this.onChangeCompUnits}
                                        min="0"
                                        max="10"
                                        disabled={(this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.isBillable === "1") ? false : true}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            : null;

            let savePropertyButton = <div className="col-sm-6 col-md-3" style={{
                padding: '0px 15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <button
                    type="button"
                    className="primary-btn btn-medium"
                    onClick={this.savePartnerProps}
                    disabled={this.state.propsLoading}
                >
                    Save Properties
                </button>
            </div>;

        let partnerPropertiesRow = this.state.propsLoading ?
            <div className="row no-gutters" style={{display: 'flex'}}>
                <div className="col-sm-12 col-md-12">
                    <div className="white-container" style={{minHeight: '100%'}}>
                        <h5 className="sectionTitle">Properties</h5>
                        <form className="input-container">
                            <div className="row no-gutters" style={{marginBottom: '15px'}}>
                                    <div className="modal-body"><Spinner animation="border" variant="dark"/></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            :
            <div className="row no-gutters" style={{display: 'flex'}}>
                <div className="col-sm-12 col-md-12">
                    <div className="white-container" style={{minHeight: '100%'}}>
                        <h5 className="sectionTitle">Properties</h5>
                        <form className="input-container">
                            <div className="row no-gutters" style={{marginBottom: '15px'}}>
                                <div className="col-sm-6 col-md-3" style={{padding: '0px 15px'}}>
                                    <label htmlFor="description">AC Cutoff Temperature</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="acCutoffTemp"
                                        value={this.state.partnerProps !== null && this.state.partnerProps !== undefined ? this.state.partnerProps.acCutoffTemp + " °F" : '0 °F'}
                                        onChange={this.onChangeAcCutoff}
                                        onBlur={this.onBlurPropertiesField}
                                    />
                                </div>
                                <div className="col-sm-6 col-md-3" style={{padding: '0px 15px'}}>
                                    <label htmlFor="description">Default Electric Price</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="electricPrice"
                                        value={this.state.partnerProps !== null && this.state.partnerProps !== undefined ? "$" + this.state.partnerProps.electricPrice : '$0.00'}
                                        onChange={this.onChangeElectricPrice}
                                        onBlur={this.onBlurPropertiesField}
                                    />
                                </div>
                                <div className="col-sm-6 col-md-3" style={{padding: '0px 15px'}}>
                                    <label htmlFor="description">Default Gas Price</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="gasPrice"
                                        value={this.state.partnerProps !== null && this.state.partnerProps !== undefined ? "$" + this.state.partnerProps.gasPrice : '$0.00'}
                                        onChange={this.onChangeGasPrice}
                                        onBlur={this.onBlurPropertiesField}
                                    />
                                </div>
                                {savePropertyButton}
                            </div>
                        </form>
                    </div>
                </div>
            </div>;

        return (
            <div>
                <div className="row no-gutters" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-6">
                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">Company</h5>
                            <form className="input-container">
                                <div>
                                    <label htmlFor="description">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.name  !== null ? this.state.currentPartner.name : ""}
                                        onChange={this.onChangeName}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="description">Display Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="display-name"
                                        defaultValue={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.company !== null ? this.state.currentPartner.company : ""}
                                        onChange={this.onChangeDisplayName}
                                    />
                                </div>
                                <div style={{ marginBottom: 10 + 'px' }}>
                                    <label htmlFor="description">Description</label>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="description"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.description !== null ? this.state.currentPartner.description : ""}
                                        onChange={this.onChangeDescription}
                                    />
                                </div>
                                
                                
                            <span>Logo:</span>
                            <div className="row">
                                    <div className="col">
                                        <span style={{ display: "none" }}>logoId={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.logoId : ""}</span>
                                        {logoImage}
                                </div>
                                <div className="col">
                                        {(!this.state.isCustomerSupport) && (
                                            <button
                                                type="button"
                                                className="secondary-btn btn-small btn-right"
                                                onClick={this.openLogoModal}
                                            >
                                                Update
                                            </button>)}
                                </div>
                            </div>
                            <span>Icon:</span>
                            <div className="row">
                                    <div className="col">
                                        <span style={{ display: "none" }}>iconId={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.iconId : ""}</span>
                                        {iconImage}
                                </div>
                                <div className="col">
                                        {(!this.state.isCustomerSupport) && (
                                            <button
                                                type="button"
                                                className="secondary-btn btn-small btn-right"
                                                onClick={this.openIconModal}
                                            >
                                                Update
                                            </button>)}
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">Contact</h5>
                            <form className="input-container">
                                <div>
                                    <label htmlFor="description">Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.address : ''}
                                        onChange={this.onChangeAddress}
                                        />
                                </div>
                                <div>
                                    <label htmlFor="description">City</label>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.city : ''}
                                        onChange={this.onChangeCity}
                                        />
                                </div>
                                <div>
                                    <label htmlFor="description">State</label>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.state : ''}
                                        onChange={this.onChangeState}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="description">Zip</label>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="zip"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.zip : ''}
                                        onChange={this.onChangeZip}
                                        />
                                </div>
                                <div>
                                    <label htmlFor="description">Phone</label>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        value={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.phone : ''}
                                        onChange={this.onChangePhone}
                                        />
                                </div>
                                <div>
                                    <label htmlFor="description">System Notification Emails</label>
                                    <AddMultipleEmailAddresses existingEmailAddresses={this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.notificationEmailAddresses : []}
                                        onAddEmailClicked={this.onAddEmailClicked}
                                        onDeleteEmailClicked={this.onDeleteEmailClicked}
                                        onEmailChanged={this.onEmailChanged} showMessage={true}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {billingRow }
                {partnerPropertiesRow}
                {subscriptionRow}
                {alertMuteUntillRow }
                <p className="status-msg">{this.state.message}</p>

                <br />
                <br /><br />
            </div>
        );
    }

    //TODO set up image error handling!

    //LFWCS-56 - HIDE ALERT MESSAGE TABLE
    //<h5>Alert Message Setup</h5>
                //<table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel" style={{ width: '95%' }}>
                //    <thead>
                //        <tr >
                //            <th>Name</th>
                //            <th>Description</th>
                //            <th>Priority Name</th>
                //            <th>Message</th>
                //            <th>Message Details</th>
                //        </tr>
                //    </thead>
                //    {tableContent}
                //</table>

    render() {
        const { classes } = this.props;

        let notPartnerAdminMsg = <div style={{ marginLeft: '10px' }}>
            <br />
            <h5>Must be a Partner Admin to access this page!</h5>
        </div>;

        let contents = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : ((this.props.roles.includes("PARTNER ADMIN") || this.props.roles.includes("ADMIN") || this.props.roles.includes("CUSTOMER SUPPORT"))  ?
                this.renderAccount()
                : notPartnerAdminMsg
            );

        return (
            <div className={classes.root}>
                <div className="infoPage">
                    <div className="row no-gutters">
                        <div className="col">
                            <h3 className="pageTitle" id="tableLabel">Partner Account</h3>
                        </div>
                       
                        <div className="col pageTitle">
                            {(!this.state.isCustomerSupport) && (
                                <div className="btn-right">
                                <button type="button" className="secondary-btn btn-large" onClick={this.openCancelModal} disabled={this.props.roles.includes("PARTNER ADMIN") || this.props.roles.includes("ADMIN") ? false : true}>Cancel</button>
                                <button type="button" className="primary-btn btn-large" onClick={this.openSaveModal} disabled={this.props.roles.includes("PARTNER ADMIN") || this.props.roles.includes("ADMIN") ? false : true}>Save</button>
                                </div>)}
                        </div>
                    </div>
                    {contents}
                    {!this.state.loading && ((this.props.roles.includes("PARTNER ADMIN") || this.props.roles.includes("ADMIN")) && (this.state.currentPartner !== null && this.state.currentPartner !== undefined && this.state.currentPartner.betaST)) &&
                        <AddIntegration currentAccount={this.props.currentAccount} partnersId={this.state.currentPartner.organizationId} />
                    }
                    <ConfirmationModal show={this.state.showCancelConfirm} onYes={this.onCancel} onClick={this.showCancelModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal show={this.state.showSaveConfirm} onYes={this.onSave} onClick={this.showSaveModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <MuteAlertConfirmationModal show={this.state.showMuteAlertConfirm} onYes={this.onSaveAlertMuteDate} onClick={this.openalertMuteUntillModal} message={this.state.modalMessage} title={this.state.modalTitle} selectedMutedDate={this.state.selectedMutedDate} onChangeMuteUntillDate={this.onChangeMuteUntillDate}></MuteAlertConfirmationModal>
                    <UpdateImageModal show={this.state.showLogoUpdate} onYes={this.onUpdateLogo} onClick={this.showLogoModal} handleImageChange={this.handleLogoChange} message={this.state.modalMessage} title={this.state.modalTitle}></UpdateImageModal>
                    <UpdateImageModal show={this.state.showIconUpdate} onYes={this.onUpdateIcon} onClick={this.showIconModal} handleImageChange={this.handleIconChange} message={this.state.modalMessage} title={this.state.modalTitle}></UpdateImageModal>

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AccountManagement);
