/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ScheduleJobModal from './ScheduleJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import ScheduleService from '../../services/schedule.service';
import HomeService from '../../services/home.service';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardActionArea } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import NotificationIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import LongArrowUpIcon from '@material-ui/icons/ArrowUpward';
import LongArrowDownIcon from '@material-ui/icons/ArrowDownward';

import { jsAsset, jsAlert, jsOpportunity, jsUpdateOpportunity, jsCustomer, jsHouse, jsDetails, jsTechnician, jsPartner } from "../../componentObjects";

import memoize from 'memoize-one';
import moment from 'moment';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CommentsModal from '../TriageAlerts/CommentsModal';
import AlertDetailsModal from './AlertDetailsModal';

const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: () => ({
        width: '200px',
        color: 'rgba(0, 0, 0, 0.65)'
    })
}

class ScheduleRepairsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
        }
    }

   

    /*openCommentsModal() {
        var job = this.props.currentJob;

        this.setState({ modalTitle: "Comments" },
            () => {
                this.showCommentsModal();

                if (job.conversationId != null) {
                    AlertService.getconversationlist(job.conversationId)
                        .then(response => {
                            if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                                this.setState({ conversation: response.data });
                                console.log("Succesfully retrieved conversation - conversationId: " + job.conversationId); //todo delete
                            }
                            else {
                                console.log("Failed to retrieve conversation - conversationId: " + job.conversationId); //todo delete
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
                else {
                    this.setState({ conversation: null });
                }
            });
    }

    showCommentsModal() {
        this.setState({
            showCommentsConfirm: !this.state.showCommentsConfirm
        });
    }

    addConversationToJob(conversationId) {
        if (this.state.currentJob.conversationId == null) {
            this.setState(prevState => ({
                currentJob: {
                    ...prevState.currentJob,
                    conversationId: conversationId,
                    conversationItemCount: this.state.currentJob.conversationItemCount != null ? (this.state.currentJob.conversationItemCount) + 1 : 1
                }
            }));

            //this.props.addConversationToJob(conversationId, this.state.currentJob.caseId); 
        }
        else {
            this.setState(prevState => ({
                currentJob: {
                    ...prevState.currentJob,
                    conversationItemCount: (prevState.currentJob.conversationItemCount) + 1
                }
            }));
        }
    }

    resetConvo(commentsAddedCount) {
        let tableAlerts = this.state.columnAltered ? this.state.columnAlertList : (this.state.tableAltered ? this.state.distinctAlertList : this.props.alertList);

        let updatedAlertsTable = tableAlerts.map(item => {
            if (item.alertId == this.state.currentAlert.alertId) {
                return {
                    ...item,
                    commentsCount: this.state.currentAlert.conversationItemCount,
                    alert: this.state.currentAlert
                };
            }
            return item;
        });

        var test = null;
        if (this.state.columnAltered) {
            this.setState({ columnAlertList: updatedAlertsTable, conversation: null, commentAlert: null });
        }
        else if (this.state.tableAltered) {
            this.setState({ distinctAlertList: updatedAlertsTable, conversation: null, commentAlert: null });
        }
        else {
            this.props.updateAlertList(updatedAlertsTable);
            this.setState({ conversation: null, commentAlert: null });
        }
    }*/

    scrollToRow = (rowId) => {
        const row = document.getElementById(rowId);
        if (row) {
            row.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    };

    onJobClick(index) {
        let x = index;
        this.props.onJobClick(x);
        this.scrollToRow(x);
    }

    getJobPriority(priority) {
        if (priority === 1) {
            return (<Tooltip title="High" enterDelay={600} leaveDelay={200}>
                <NotificationIcon className="db-red db-red-bg" style={{ borderRadius: '12px', padding: '3px' }} />
            </Tooltip>
            );
        }
        else if (priority === 2) {
            return (<Tooltip title="Medium" enterDelay={600} leaveDelay={200}>
                <NotificationIcon className="db-orange db-orange-bg" style={{ borderRadius: '12px', padding: '3px' }} />
            </Tooltip>
            );
        }
        else {
            return (<Tooltip title="Low" enterDelay={600} leaveDelay={200}>
                <NotificationIcon style={{ borderRadius: '12px', padding: '3px', color: 'grey', backgroundColor: 'gainsboro' }} />
            </Tooltip>
            );
        }
    }

    getDate(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment(str).format("l h:mm A");
        }
        return (date);
    }

    getAddress() {
        var addy = "";

        if (this.props.currentHome !== undefined && this.props.currentHome.address !== "" && this.props.currentHome.address !== null) {
            addy = this.props.currentHome.address;

            if (this.props.currentHome.city !== "" && this.props.currentHome.city !== null) {
                addy += ", " + this.props.currentHome.city;
            }

            if (this.props.currentHome.state !== "" && this.props.currentHome.state !== null) {
                addy += ", " + this.props.currentHome.state;

                if (this.props.currentHome.zip !== "" && this.props.currentHome.zip !== null)
                    addy += " " + this.props.currentHome.zip;
            }
        }
        return (addy);
    }

    /*getCommentCount(convoId) {
        let commentsContent = (convoId !== null) ?
            <div style={{ fontSize: "12", position: "relative", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                <ChatBubbleIcon style={{ fontSize: "2.1em" }} color="primary" />
                <Typography component="span" style={{ position: "absolute", color: "#fff" }}>
                    {count}
                </Typography>
            </div>
            : <div>None</div>;

        return (
            <td>{commentsContent}</td>
        );
    }*/

    renderExpandContent(index, job) {
        const { classes } = this.props;

        this.scrollToRow(index);

        let expandedLoader = this.props.expandLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}><div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div></div>
            : null;

        let alertDetailsButton = (this.props.selected > -1 && this.props.currentJobDetails.number.includes('A')) ?
            <div>
                <button className="secondary-btn btn-small" style={{ width: '112px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={this.props.onDetailsClick}>View Alert Details</button>
            </div>
            : <div />;

        let closeButton = this.props.tableRadio !== "Scheduled" && this.props.tableRadio !== "Deferred" ?
            null 
            : <div>
                <button className="secondary-btn btn-small" style={{ width: '112px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.props.openCloseJobModal(this.props.currentJob)}>Close Job</button>
            </div>;

        let deferrButton = this.props.tableRadio !== "Scheduled" ?
            null 
            : <div>
                <button className="secondary-btn btn-small" style={{ width: '112px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.props.openDeferJobModal(this.props.currentJob)}>Defer Job</button>
            </div>;

        let jobButton = this.props.tableRadio == "Unscheduled" || this.props.tableRadio == "Deferred" ?
            <div>
                <button className="secondary-btn btn-small" style={{ width: '112px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.props.openScheduleJobModal(this.props.currentJob)}>Schedule Job</button>
            </div>
            :  < div >
                <button className="secondary-btn btn-small" style={{ width: '112px', marginBottom: '5px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.props.openUpdateJobModal(this.props.currentJob)}>Update Job</button>
            </div >;

        let customerEmail = this.props.currentJobCustomer.familyMembers !== null && this.props.currentJobCustomer.familyMembers !== undefined ?
            (this.props.currentJobCustomer.familyMembers[0] !== undefined ?
                <p><span style={{ color: 'grey' }}>Email</span><br />
                    {this.props.currentJobCustomer.familyMembers[0].email}
                </p>
                : null)
            : null;

        let valueDetail = this.props.tableRadio == "Unscheduled" ?
            null
            : <p><span style={{ color: 'grey' }}>Job Value</span><br />
                {/*{this.props.currentJobDetails.impactEstimate == null ? "Not yet provided" : "$ " + String(this.props.currentJobDetails.impactEstimate)}*/}
                {(this.props.scheduleDetails?.jobvalue == null || this.props.scheduleDetails?.jobvalue == undefined || this.props.scheduleDetails?.jobvalue == "") ? "Not yet provided" :  String(this.props.scheduleDetails.jobvalue)}
            </p>;

        let notesDetail = this.props.currentJobDetails.comment == null?
            null
            : <p><span style={{ color: 'grey' }}>Notes</span><br />
                {/*{this.props.currentJobDetails.comment == null ? "" : this.props.currentJobDetails.comment}*/}
                {(this.props.scheduleDetails?.jobNotes == null || this.props.scheduleDetails?.jobNotes == undefined || this.props.scheduleDetails?.jobNotes == "") ? "" : this.props.scheduleDetails.jobNotes}
            </p>;
        let LW_JobID =
          
             <p><span style={{ color: 'grey' }}> LW_JobID</span><br />
                {/*{this.props.currentJobDetails.comment == null ? "" : this.props.currentJobDetails.comment}*/}
                {(this.props.scheduleDetails?.lwJobID == null || this.props.scheduleDetails?.lwJobID == undefined || this.props.scheduleDetails?.lwJobID == "") ? "" : this.props.scheduleDetails.lwJobID}
            </p>;
        let ST_ID = 
             <p><span style={{ color: 'grey' }}>ST_ID</span><br />
                {/*{this.props.currentJobDetails.comment == null ? "" : this.props.currentJobDetails.comment}*/}
                {(this.props.scheduleDetails?.stID == null || this.props.scheduleDetails?.stID == undefined || this.props.scheduleDetails?.stID == "") ? "" : this.props.scheduleDetails.stID}
            </p>;

        if (this.props.selected == index) {
            return (
                <tr className="expanded" key={`expandedContent-${index}`} style={{ backgroundColor: 'white !important' }}>
                    <td colSpan={6} style={{ color: 'unset' }}>
                        <div className="row">
                            <div className="col" style={{ paddingLeft: '45px' }}>
                                <h6 className="db-blue">Customer</h6>
                                <p><span style={{ color: 'grey' }}>Name</span><br />
                                    {this.props.currentJobCustomer.name}
                                </p>
                                <p><span style={{ color: 'grey' }}>Phone Number</span><br />
                                    {this.props.currentJobCustomer.phone}
                                </p>
                                {customerEmail}
                                <p><span style={{ color: 'grey' }}>Address</span><br />
                                    {this.getAddress()}
                                </p>
                            </div>

                            <div className="col-5" style={{ paddingLeft: '45px' }}>
                                <h6 className="db-blue">Job Details</h6>
                                <p><span style={{ color: 'grey' }}>Scheduled For</span><br />
                                    {/*{this.props.currentJob.scheduledForTimeStr == "" ? (this.props.tableRadio == "Closed" ? "Already Completed" : "Not yet scheduled") : this.getDate(this.props.currentJob.scheduledForTimeStr)}*/}
                                    {(this.props.scheduleDetails?.scheduledDate == "" || this.props.scheduleDetails?.scheduledDate == null || this.props.scheduleDetails?.scheduledDate == undefined) ? (this.props.tableRadio == "Closed" ? "Already Completed" : "Not yet scheduled") : this.getDate(this.props.scheduleDetails?.scheduledDate)}
                                </p>
                                <p><span style={{ color: 'grey' }}>Assigned To</span><br />
                                    {/*{this.props.currentJob.assignedToName == null || this.props.currentJob.assignedToName == "" ? "Unassigned" : this.props.currentJob.assignedToName}*/}
                                    {this.props.scheduleDetails?.technician == null || this.props.scheduleDetails?.technician == "" ? "Unassigned" : this.props.scheduleDetails.technician}
                                </p>
                                <p><span style={{ color: 'grey' }}>Verification Status</span><br />
                                    {this.props.currentJobDetails.isFalsePositive == null ? "Unverified" : (this.props.currentJobDetails.isFalsePositive == true ? "False Positive" : "Verified")}
                                </p>
                                {valueDetail}
                                {notesDetail}
                                {LW_JobID}
                                {ST_ID}

                            </div>
                            <div className="col-3">
                                <h6 className="db-blue">Take Action</h6>
                                <div>
                                    {alertDetailsButton}
                                    {(!this.state.isCustomerSupport) && (<div>
                                        {jobButton}

                                        {closeButton}

                                        {deferrButton}

                                        {/*<div>
                                        <button className="secondary-btn btn-small" style={{ width: '112px', borderRadius: '.2rem', height: '25px' }} onClick={() => this.openCommentsModal(alert.alert)}>Comments</button>
                                    </div>*/}
                                    </div>)}

                                </div>
                            </div>

                            {expandedLoader}
                        </div>
                    </td>
                </tr>
            );
        } 
        else 
            return (<div>{null}</div>);
    }

    renderTable() {
        let tableJobs = this.props.columnAltered ? this.props.columnList : this.props.opportunityList;  
        let selectedIndex = this.props.selected; 

        let jobsContent = tableJobs !== null && tableJobs !== undefined && tableJobs.length ?
            <tbody>
                {tableJobs.map((job, index) =>
                    [<tr className={selectedIndex == index ? "alerts-table-selected alerts-table-row" : "alerts-table-row"} key={`${job.opportunityId}-tableRow`} id={index} data={index} value={index} onClick={() => this.onJobClick(index)}>
                        <td className="alerts-td-left">
                            {this.getJobPriority(job.priority)}
                        </td>
                        {this.props.tableRadio == "Closed" ?
                            null
                            : <td style={{ minWidth: '150px' }}>{job.customer == "" || job.customer == undefined ? "" : job.customer.split(",")[0]}</td>
                        }

                        <td style={{ maxWidth: '400px' }}>
                            <div style={{ maxWidth: '400px' }}>
                                {job.subject}
                            </div>
                        </td>

                        {/*this.getCommentCount(job.conversationId)*/}
                        <td style={{ minWidth: '150px' }}>{this.getDate(job.createdOnTimeStr)}</td>
                        <td className="alerts-td-right">
                            {selectedIndex == index ?
                                <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} />
                                : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />
                            }
                        </td>
                    </tr>,
                    selectedIndex == index && (this.renderExpandContent(index, job))
                    ])}

            </tbody>
            : <tbody>
                <tr>
                    <td>No Leads!</td>
                </tr>
            </tbody>;

        let arrowIcon = this.props.columnSort.enabled ?
            (this.props.columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let priorityIcon = this.props.columnSort.enabled && this.props.columnSort.type === "PRIORITY" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let custIcon = this.props.columnSort.enabled && this.props.columnSort.type === "CUSTOMER" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        let jobIcon = this.props.columnSort.enabled && this.props.columnSort.type === "JOB" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
        /*let commentsIcon = this.state.columnSort.enabled && this.state.columnSort.type === "COMMENTS" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;*/
        let createdIcon = this.props.columnSort.enabled && this.props.columnSort.type === "CREATED" ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return (
            <div style={{ margin: '10px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}>
                                    <div onClick={() => this.props.onColumnClick("PRIORITY")}>
                                        <span style={{ cursor: 'pointer' }}>PRIORITY</span>
                                        {priorityIcon}
                                    </div>
                                </th>
                                {this.props.tableRadio == "Closed" ?
                                    null
                                    : <th>
                                        <div onClick={() => this.props.onColumnClick("CUSTOMER")}>
                                            <span style={{ cursor: 'pointer' }}>CUSTOMER</span>
                                            {custIcon}
                                        </div>
                                    </th>
                                }
                                <th>
                                    <div onClick={() => this.props.onColumnClick("JOB")}>
                                        <span style={{ cursor: 'pointer' }}>JOB</span>
                                        {jobIcon}
                                    </div>
                                </th>

                                {/*<th>
                                    <div onClick={() => this.onColumnClick("COMMENTS")}>
                                        <span style={{ cursor: 'pointer' }}>COMMENTS</span>
                                        {commentsIcon}
                                    </div>
                                </th>*/}
                                <th>
                                    <div onClick={() => this.props.onColumnClick("CREATED")}>
                                        <span style={{ cursor: 'pointer' }}>JOB CREATION DATE</span>
                                        {createdIcon}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
                            </tr>
                        </thead>

                        {jobsContent}

                    </table>
                </div>
            </div>
        );
    }

    renderContent() {
        const { classes } = this.props;

        let tableContents = this.state.tableLoading || this.props.tableLoading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderTable();

        return (
            <div>
                <div className="alerts-tab-wrapper" style={{ margin: '10px 30px 0px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
                    <div className="alerts-tab-label" style={{ paddingLeft: '45px' }}>Leads</div>
                    <div style={{ paddingLeft: '40px', margin: '5px' }}>
                        <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>

                            <input type="radio" value="Unscheduled" onChange={e => this.props.changeTableRadio(e)} checked={this.props.tableRadio == "Unscheduled"} name="jobTableType" />
                                &nbsp; Unscheduled &nbsp;&nbsp;
                            <input type="radio" value="Scheduled" onChange={e => this.props.changeTableRadio(e)} checked={this.props.tableRadio == "Scheduled"} name="jobTableType" />
                                &nbsp; Scheduled &nbsp;&nbsp;
                            <input type="radio" value="Closed" onChange={e => this.props.changeTableRadio(e)} checked={this.props.tableRadio == "Closed"} name="jobTableType" />
                                &nbsp; Closed &nbsp;&nbsp;
                            <input type="radio" value="Deferred" onChange={e => this.props.changeTableRadio(e)} checked={this.props.tableRadio == "Deferred"} name="jobTableType" />
                                &nbsp; Deferred &nbsp;&nbsp;

                        </div>
                    </div>
                </div>

                <div>
                    {tableContents}
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let contents = this.props.loading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderContent();

        return (
            <div>
                {contents}
            </div>
        );
    }
}

export default withStyles(styles)(ScheduleRepairsTable);