/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class MapPointModal extends Component {
    constructor(props) {
        super(props);

        this.onCurrentJobClick = this.onCurrentJobClick.bind(this);
        this.setCustomerCallback = this.setCustomerCallback.bind(this);

        this.state = {
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
    };

    onCurrentJobClick = () => {
        this.props.setCurrentCustomerId(this.props.customer.organizationId, this.setCustomerCallback);
    }

    setCustomerCallback() {
        this.props.setActivePath("/currentJob");
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else if ((this.props.customer === undefined || this.props.customer === null) && (this.props.point === undefined || this.props.point === null)) {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Map Point Info</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            <p> Error fetching point and customer info! </p>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.props.customer === undefined || this.props.customer === null) {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Map Point Info</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            <p> Error fetching customer info! </p>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.props.point === undefined || this.props.point === null) {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Map Point Info</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            <p> Error fetching point info! </p>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Map Point Info</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body-2" style={{ textAlign: "left" }}>
                            <form>
                                <div className="row">
                                    <div className="col">
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Customer</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={this.props.customer.name}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Phone</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="description"
                                                value={this.props.customer.phone}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Email</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="type"
                                                value={this.props.customer.email}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Address</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="startTime"
                                                value={this.props.point.address}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Open Active Alerts</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="startTime"
                                                value={this.props.point.openActiveAlerts}
                                                disabled={true}
                                            />
                                        </div>
                                        <div style={{ paddingRight: 2 + "px" }}>
                                            <p className="formLbl">Open Inactive Alerts</p>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="startTime"
                                                value={this.props.point.openInactiveAlerts}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <Link to="/currentJob" className="primary-btn btn-small" onClick={this.onCurrentJobClick}>
                                View Alerts
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MapPointModal;