/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/alerttypemessaging";
const alerttypemessagingService = {
    getpartnermessaging: async (partnerOrgId) => {
        const response = await http.post(`${controller}/getpartnermessaging?partnerOrgId=${partnerOrgId}`);
        return response.data;
    },
    getalertmessaging: async () => {
        const response = await http.get(`${controller}`);
        return response.data;
    }
};
export default alerttypemessagingService;